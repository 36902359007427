import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CheckboxIcon = (props) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g id="checkbox">
        <g id="Rectangle 87">
          <rect width="16" height="16" rx="2" fill="white" />
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="1.5"
            stroke="#6D6979"
            strokeOpacity="0.5"
            fill="none"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default CheckboxIcon;
