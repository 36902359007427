import React from 'react'

import { ThemeProvider } from '@mui/material'

import { theme as defaultTheme } from '@/lib/theme'
import { theme as oldTheme } from '~/muiTheme'
import { theme as payrollsTheme } from '~/pages/payrolls/payrollsTheme'

const themeMap = {
  default: defaultTheme,
  old: oldTheme,
  payrolls: payrollsTheme,
}

export function withTheme<P>(Component: React.ComponentType<P>, themeName: keyof typeof themeMap = 'default') {
  return React.forwardRef<unknown, P>(function ThemedComponent(props, ref) {
    return (
      <ThemeProvider theme={themeMap[themeName]}>
        <Component ref={ref} {...(props as P)} />
      </ThemeProvider>
    )
  })
}
