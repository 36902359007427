import { useEffect } from "react";
import { setUser } from "@sentry/react";

import ErrorBoundary from "../ErrorBoundary";
import { authService } from "../../core";
import { localStorageSyncApi } from "../../core/localStorageSyncApi";
import { sessionStorageSyncApi } from "../../core/sessionStorageSyncApi";
import { useAppState } from "@/states/app-state";

function SentryUserManager() {
  const profile = useAppState((state) => state.profile);

  useEffect(() => {
    if (!profile) return;
    const jwtGuid = authService.getJwtGuid();
    const bfp = localStorageSyncApi.getOrSetBfp();
    const sfp = sessionStorageSyncApi.getOrSetSfp();
    setUser({
      ...profile,
      jwtGuid,
      bfp,
      sfp,
      "x-react-app-version": process.env.REACT_APP_VERSION,
    });
  }, [profile]);
  return null;
}

const WrappedSentryUserManager = () => {
  return (
    <ErrorBoundary noFallback>
      <SentryUserManager />
    </ErrorBoundary>
  );
};

export default WrappedSentryUserManager;
