import React from "react";
import { createTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CheckedIcon from "./components/Icons/CheckedIcon";
import UncheckedIcon from "./components/Icons/UncheckedIcon";
import RadioButtonIcon from "./components/Icons/RadioButtonIcon";
import RadioButtonCheckedIcon from "./components/Icons/RadioButtonCheckedIcon";
import ArrowForwardIosIcon from "./components/Icons/ArrowForwardIosIcon";

const basePaletteTheme = createTheme({
  palette: {
    grey: {
      50: "#F6F6F6",
      100: "#E7E7E7",
      200: "#D1D1D1",
      300: "#B0B0B0",
      400: "#898989",
      500: "#6D6D6D",
      600: "#5D5D5D",
      700: "#4F4F4F",
      800: "#454545",
      900: "#3D3D3D",
    },
  },
});

export const theme = createTheme({
  mixins: {
    scrollbar: {
      "& ::-webkit-scrollbar": {
        width: 6,
      },
      "& ::-webkit-scrollbar:horizontal": {
        height: 6,
      },
      "& ::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 2.5,
        border: "3px solid transparent",
        backgroundClip: "padding-box",
      },
      "&:hover": {
        "& ::-webkit-scrollbar-thumb": {
          border: "3px solid #000000",
        },
      },
      scrollbarWidth: "thin",
      scrollbarColor: "transparent",
    },
    scrollbarLight: {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 2,
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 20,
        border: "3px solid transparent",
        backgroundClip: "padding-box",
      },
      "&:hover": {
        "&::-webkit-scrollbar": {
          width: 2,
          marginTop: 3,
        },
        "&::-webkit-scrollbar-thumb": {
          border: "3px solid #c4c4c4",
        },
      },
    },
    scrollbarLarge: {
      "& ::-webkit-scrollbar": {
        width: 12,
      },
      "& ::-webkit-scrollbar:horizontal": {
        height: 12,
      },
      "& ::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 5,
        border: "6px solid #000000",
        backgroundClip: "padding-box",
      },
      "&:hover": {
        "& ::-webkit-scrollbar-thumb": {
          border: "6px solid #000000",
        },
      },
      scrollbarWidth: "thin",
      scrollbarColor: "transparent",
    },
    drawerWidth: 260,
    navBarHeight: 48,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "TTNorms",
    h1: {
      fontSize: 32,
      lineHeight: "41px",
      fontWeight: 500,
      color: "#3F1AC4",
    },
    h2: {
      fontSize: 24,
      lineHeight: "31px",
      fontWeight: 500,
      color: "#140936",
    },
    h3: {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 500,
      color: "#140936",
    },
    h4: {
      fontSize: 14,
      lineHeight: "18px",
      fontWeight: 500,
      color: "#140936",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
    body1: {
      fontSize: 14,
      lineHeight: "18px",
      fontWeight: 400,
      color: "#6D6979",
    },
    body2: {
      fontSize: 12,
      lineHeight: "15px",
      fontWeight: 400,
      color: "#6D6979",
    },
    navLink: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "19px",
    },
    button: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "18px",
    },
    sectionTitle: {
      color: "#140936",
      fontSize: 16,
      fontWeight: 500,
    },
    sectionLabel: {
      color: "#666666",
      fontSize: 12,
    },
  },
  palette: {
    text: {
      primary: basePaletteTheme.palette.grey[900],
      secondary: basePaletteTheme.palette.grey[500],
      disabled: basePaletteTheme.palette.grey[300],
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2c11a3",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#E8FFFF",
      main: "#1cf2e2",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    purpleLight: {
      light: "#F7F9F9",
      main: "#E2E0EF",
      dark: "#FAFAFC",
    },
    purple: {
      light: "#E7E5EA",
      main: "#320DA0",
      dark: "#140936",
    },
    purpleDark: {
      light: "#3F1AC4",
      main: "#200868",
      dark: "#F5F4F8",
    },
    gold: {
      main: "#d6c155",
    },
    green: {
      light: "#64C52A",
      main: "#12EA12",
      dark: "#7EF1E2",
    },
    greenLight: {
      main: "#E9FFFD",
    },
    greenMain: {
      main: "#61B54B",
    },
    greenDark: {
      main: "#008000",
    },
    yellowLight: {
      main: "#FFFCEF",
    },
    yellow: {
      light: "#FFFDEF",
      main: "#DB8C15",
    },
    orange: {
      light: "#FFA200",
      main: "#FFA500",
    },
    red: {
      alpha: "#E859591A",
      light: "#E85959",
      main: "#FF0000",
      dark: "#B94040",
    },
    blue: {
      dark: "#151395",
    },
    error: {
      main: "#B94040",
      dark: "#B94040",
    },
    black: {
      main: "#140936",
    },
    grey: {
      50: "#F1F1F1",
      100: "#f6f9f9",
      200: "#6D6979",
      300: "#ecf3f6",
      400: "#656565",
      500: "#6f7374",
      600: "#666666",
      700: "#707070",
      800: "#444444",
      900: "#a9a9a9",
      // A100: "#fafafa",
      // A200:  "#fafafa",
      // A400: "#fafafa",
      // A700: "#fafafa",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          backgroundColor: theme.palette.grey[100],
        },
      }),
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          sectionTitle: "h6",
          sectionLabel: "p1",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "inherit",
        variant: "outlined",
        elevation: 0,
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
        elevation: 0,
      },
      styleOverrides: {
        outlined: ({ theme }) => ({
          borderColor: theme.palette.purpleLight.main,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({}) => ({
          textTransform: "none",
        }),
        containedPrimary: () => ({
          backgroundColor: "#3F1AC4",
          "&:hover": {
            backgroundColor: "#2C1093",
          },
        }),
        containedSecondary: () => ({
          backgroundColor: "rgba(63, 26, 196, 0.08)",
          color: "#3F1AC4",
          "&:hover": {
            backgroundColor: "rgba(63, 26, 196, 0.08)",
            outline: "1px solid rgba(63, 26, 196, 0.5)",
          },
        }),
        outlinedPrimary: () => ({
          border: "1px solid #3F1AC4",
          color: "#3F1AC4",
          "&:hover": {
            border: "1px solid #3F1AC4",
            color: "#3F1AC4",
            backgroundColor: "#EDEBF5",
          },
        }),
        outlinedInfo: () => ({
          border: "1px solid rgba(109, 105, 121, 0.25)",
          color: "#6D6979",
          "&:hover": {
            border: "1px solid rgba(109, 105, 121, 0.5)",
            color: "#6D6979",
            backgroundColor: "rgba(109, 105, 121, 0.05)",
          },
        }),
        outlinedSuccess: ({ theme }) => ({
          border: "1px solid",
          borderColor: theme.palette.green.dark,
          color: "#3F1AC4",
          "&:hover": {
            borderColor: theme.palette.green.dark,
            backgroundColor: theme.palette.green.dark,
            color: "#3F1AC4",
          },
        }),
        textInfo: () => ({
          color: "#6D6979",
          "&:hover": {
            color: "#6D6979",
            backgroundColor: "rgba(109, 105, 121, 0.05)",
          },
        }),
        sizeLarge: ({ theme }) => ({
          padding: theme.spacing(1.9375, 2.75),
          borderRadius: theme.spacing(0.625),
          fontSize: 15,
          fontWeight: 500,
          lineHeight: "19px",
        }),
        outlinedSizeLarge: ({ theme }) => ({
          padding: theme.spacing(1.8125, 2.75),
        }),
        sizeMedium: ({ theme }) => ({
          padding: theme.spacing(1.625, 1.875),
          borderRadius: theme.spacing(0.625),
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "18px",
        }),
        outlinedSizeMedium: ({ theme }) => ({
          padding: theme.spacing(1.5, 1.875),
        }),
        sizeSmall: ({ theme, ownerState }) => ({
          padding: theme.spacing(1.25, 1.875),
          borderRadius: theme.spacing(0.625),
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "18px",
          ...(ownerState.variant === "outlined" && { padding: theme.spacing(1.125, 1.875) }),
        }),
        startIcon: () => ({
          ">*:nth-of-type(1)": {
            fontSize: 14,
          },
        }),
        endIcon: ({ theme, ownerState }) => ({
          stroke: theme.palette.common.white,
          ...(ownerState.color === "primary" &&
            ownerState.variant === "outlined" && {
              stroke: "#3F1AC4",
            }),
          path: {
            opacity: 1,
          },
          ".MuiSvgIcon-root": {
            fontSize: 14,
          },
        }),
      },
    },
    MuiFab: {
      defaultProps: {
        color: "primary",
        size: "medium",
      },
      styleOverrides: {
        root: () => ({
          boxShadow: "none",
          width: 34,
          height: 34,
        }),
        primary: ({ theme }) => ({
          backgroundColor: theme.palette.green.dark,
          ".MuiSvgIcon-root": {
            fontSize: 16,
          },
          "&:hover": {
            backgroundColor: `${theme.palette.green.dark}80`,
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <UncheckedIcon />,
        checkedIcon: <CheckedIcon />,
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: <RadioButtonIcon sx={{ fill: "transparent", width: 20, height: 20 }} />,
        checkedIcon: <RadioButtonCheckedIcon sx={{ fill: "transparent", width: 20, height: 20 }} />,
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        variant: "standard",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          ...theme.typography.h4,
          fontWeight: 400,
          "& span": {
            ...theme.typography.body2,
          },
        }),
      },
    },
    MuiInputBase: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor: theme.palette.purpleLight.light,
          "label + &": {
            marginTop: theme.spacing(1.75),
          },
          "&.MuiOutlinedInput-root": {
            backgroundColor: theme.palette.common.white,
            ...(ownerState.color === "secondary" && {
              backgroundColor: theme.palette.purpleLight.light,
            }),
          },
        }),
        input: ({ theme, ownerState }) => ({
          borderRadius: 4,
          position: "relative",
          backgroundColor: theme.palette.common.white,
          fontSize: 16,
          padding: "10px 12px",
          transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
          "&:focus": {
            borderColor: theme.palette.primary.main,
          },
          "::-webkit-input-placeholder": {
            color: "#6D6979",
          },
          ":-moz-placeholder": {
            color: "#6D6979",
            opacity: 0.5,
          },
          "::-moz-placeholder": {
            color: "#6D6979",
            opacity: 0.5,
          },
          ":-ms-input-placeholder": {
            color: "#6D6979",
          },
          "::-ms-input-placeholder": {
            color: "#6D6979",
          },

          "::placeholder": {
            color: "#6D6979",
          },
          ...(ownerState.color === "secondary" && {
            backgroundColor: theme.palette.purpleLight.light,
            "::-webkit-input-placeholder": {
              color: theme.palette.purple.dark,
            },
            ":-moz-placeholder": {
              color: theme.palette.purple.dark,
              opacity: 0.5,
            },
            "::-moz-placeholder": {
              color: theme.palette.purple.dark,
              opacity: 0.5,
            },
            ":-ms-input-placeholder": {
              color: theme.palette.purple.dark,
            },
            "::-ms-input-placeholder": {
              color: theme.palette.purple.dark,
            },

            "::placeholder": {
              color: theme.palette.purple.dark,
            },
          }),
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        disableAnimation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          color: theme.palette.purple.dark,
          lineHeight: "18px",
          transform: "translate(0px, -8px) scale(1)",
          "&.Mui-focused": {
            color: theme.palette.purple.dark,
          },
          "&.Mui-error": {
            color: theme.palette.error.main,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: "18px",
          borderColor: theme.palette.purpleLight.main,
          "& .MuiOutlinedInput-notchedOutline": {},
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
              borderColor: "#6D6979",
            },
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#6D6979",
            },
          },
        }),
        input: ({ theme, ownerState }) => ({
          height: "unset",
          padding: theme.spacing(1.5, 1.5),
          ...(ownerState.size === "large" && {
            padding: theme.spacing(2, 1.5),
          }),
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.purpleLight.main,
          legend: {
            maxWidth: 0,
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        outlined: () => ({
          minHeight: "unset",
          padding: theme.spacing(1.5, 1.5),
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: () => ({
          // boxShadow: "0px 1px 3px #00000014"
        }),
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {},
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: "rgba(245, 244, 248, 0.5) !important",
          "&:nth-of-type(even)": {
            backgroundColor: "rgba(245, 244, 248, 0.5) !important",
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.secondary.light,
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          backgroundColor: theme.palette.purpleDark.dark,
          color: theme.palette.purple.dark,
          fontSize: 14,
          fontWeight: 500,
        }),
        body: () => ({
          fontSize: 14,
          color: theme.palette.grey[200],
          border: "none",
          "& a": {
            color: theme.palette.purple.main,
          },
        }),
      },
    },
    MuiTablePagination: {
      defaultProps: {
        SelectProps: {
          fullWidth: false,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.purpleLight.main}`,
          borderRadius: theme.spacing(0.625),
          marginBottom: theme.spacing(1.875),
          "&:before": {
            display: "none",
          },
          "&.Mui-disabled": {
            border: `1px solid ${theme.palette.purpleLight.main}`,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: (
          <ArrowForwardIosIcon
            sx={{
              fontSize: 12,
              color: "purple.dark",
            }}
          />
        ),
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          paddingLeft: theme.spacing(1),
          borderRadius: theme.spacing(0.625),
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper": {
            transform: "rotate(90deg)",
            padding: theme.spacing(1.5),
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(270deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(0),
          },
        }),
        content: ({ theme }) => ({
          color: theme.palette.purple.dark,
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2, 2, 2, 5.5),
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => ({
          width: 28,
          height: 20,
          padding: 0,
          margin: theme.spacing(1),
        }),
        sizeSmall: () => ({
          width: 24,
          height: 16,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 3,
            "&.Mui-checked": {
              transform: "translateX(7px)",
            },
          },
          "& .MuiSwitch-thumb": {
            width: 10,
            height: 10,
            borderRadius: 5,
          },
          "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
          },
        }),
        switchBase: ({ theme }) => ({
          padding: 4,
          "&.Mui-checked": {
            transform: "translateX(8px)",
            color: theme.palette.common.white,
            "& + .MuiSwitch-track": {
              backgroundColor: "#4426D9",
              opacity: 1,
              border: "none",
            },
          },
        }),
        thumb: () => ({
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(["width"], {
            duration: 200,
          }),
        }),
        track: () => ({
          borderRadius: 20 / 2,
          opacity: 0.5,
          backgroundColor: theme.palette.grey[700],
          boxSizing: "border-box",
          transition: theme.transitions.create(["background-color"]),
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: () => ({
          textTransform: "none",
        }),
      },
    },
    MuiDataGrid: {
      defaultProps: {
        getRowClassName: (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"),
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        pagination: true,
        componentsProps: {
          filterPanel: {
            filterFormProps: {
              sx: { gap: 1, pt: 2 },
              deleteIconProps: {
                sx: {
                  width: "auto",
                },
              },
              columnInputProps: {
                variant: "outlined",
              },
              operatorInputProps: {
                variant: "outlined",
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                },
              },
            },
          },
        },
      },
      styleOverrides: {
        root: () => ({
          border: "none",
        }),
        columnHeaderTitle: ({ theme }) => ({
          fontSize: 14,
          lineHeight: "18px",
          fontWeight: 500,
          color: theme.palette.purple.dark,
        }),
        columnHeaderRow: ({ theme }) => ({
          backgroundColor: theme.palette.purpleDark.dark,
          color: theme.palette.purple.dark,
          "> .MuiDataGrid-columnHeader": {
            paddingLeft: 20,
          },
          "> .MuiDataGrid-columnHeader ~ .MuiDataGrid-columnHeader": {
            paddingLeft: 10,
          },
          "& .MuiDataGrid-columnHeaderCheckbox": {
            paddingLeft: 14,
          },
        }),
        columnHeaders: () => ({
          backgroundColor: theme.palette.purpleDark.dark,
          borderRadius: 0,
        }),
        columnHeader: () => ({
          "&:focus": {
            outline: "none",
          },
        }),
        row: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.purpleDark.dark}`,
          "> div:first-of-type": {
            paddingLeft: 20,
          },
          "&:hover": {
            backgroundColor: "#F8F5FF",
          },
        }),
        cell: ({ theme }) => ({
          fontSize: 14,
          color: theme.palette.grey[600],
          border: "none",
          "& a": {
            color: theme.palette.purple.dark,
            textDecoration: "none",
            fontWeight: 500,
          },
          "&:focus": {
            outline: "none",
          },
        }),
        main: ({ theme }) => ({
          ...theme.mixins.scrollbar,
        }),
        filterFormLinkOperatorInput: {
          width: "auto",
        },
        filterFormLogicOperatorInput: {
          maxWidth: 80,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& .MuiAutocomplete-input": {
              padding: theme.spacing(1.5, 1.5),
            },
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiFormHelperText-root": {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: theme.palette.purple.main,
          fontSize: 14,
          lineHeight: "18px",
          fontWeight: 400,
          ...(ownerState.variant === "body2" && {
            ...theme.typography.body2,
            color: theme.palette.purple.main,
          }),
        }),
      },
    },
  },
});
