// ACTION TYPES
const TYPES = {
  OPEN_SELECT_COMPANY_MODAL: "OPEN_SELECT_COMPANY_MODAL",
  CLOSE_SELECT_COMPANY_MODAL: "CLOSE_SELECT_COMPANY_MODAL"
};

// ACTION CREATORS
export const openSelectCompanyModal = () => ({ type: TYPES.OPEN_SELECT_COMPANY_MODAL });
export const closeSelectCompanyModal = () => ({ type: TYPES.CLOSE_SELECT_COMPANY_MODAL });

// REDUCER
const initialState = {
  isModalOpened: false
};

export const widgetSelectCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_SELECT_COMPANY_MODAL:
      return {
        ...state,
        isModalOpened: true
      };
    case TYPES.CLOSE_SELECT_COMPANY_MODAL:
      return {
        ...state,
        isModalOpened: false
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsModalOpened = state => state.widgetSelectCompany.isModalOpened;
