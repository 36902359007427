import React from 'react'

import type { TransitionProps } from '@mui/material/transitions'

import { Slide } from '@mui/material'

export const DialogTransition = React.forwardRef(function DialogTransition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})
