const TYPES = {
  OPEN_NEW_DOWNLOAD_MODAL: "OPEN_NEW_DOWNLOAD_MODAL",
  CLOSE_NEW_DOWNLOAD_MODAL: "CLOSE_NEW_DOWNLOAD_MODAL"
};

export const openNewDownloadModalAC = report => ({
  type: TYPES.OPEN_NEW_DOWNLOAD_MODAL,
  payload: {
    report
  }
});

export const closeNewDownloadModalAC = id => ({
  type: TYPES.CLOSE_NEW_DOWNLOAD_MODAL,
  payload: {
    id
  }
});

const initialState = {
  reports: []
  // reportData: {
  //   id: 0,
  //   isNew: true,
  //   lastDownloaded: null,
  //   reportName: "",
  //   reportParametersDescription: "",
  //   requestedOn: "",
  //   status: ""
  // }
};

export const widgetNewDownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_NEW_DOWNLOAD_MODAL: {
      // Check for duplicates
      if (state.reports.some(el => el.id === action.payload.report.id)) {
        return state;
      } else {
        return {
          ...state,
          reports: [...state.reports, action.payload.report]
        };
      }
    }
    case TYPES.CLOSE_NEW_DOWNLOAD_MODAL:
      return {
        ...state,
        reports: state.reports.filter(el => el.id !== action.payload.id)
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectReports = state => state.widgetNewDownload.reports;
