import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CheckboxCheckedIcon = (props) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <rect x="1" y="1" width="16" height="16" rx="2" fill="#3F1AC4" />
      <rect x="1" y="1" width="16" height="16" rx="2" fill="white" fillOpacity="0.25" />
      <path
        d="M4.5 9.00037L7.68198 12.1823L14.0453 5.81836"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default CheckboxCheckedIcon;
