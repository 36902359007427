import React, { useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { PAGES } from "./pages";
import { authService, logErrorsService } from "../core";
import { useAppState } from "@/states/app-state";

const ProtectedRoute = ({
  component: Component,
  path,
  title,
  userOnlyAuthenticated,
  permission,
  disabled,
  render,
}) => {
  const profile = useAppState((state) => state.profile);
  const initiated = useAppState((state) => state.initiated);
  const permissions = profile?.permission;
  const location = useLocation();
  const locationName = location.pathname;
  const [routeProps, setRouteProps] = useState();
  const profileRef = useRef();

  function delay(time) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
    });
  }

  const defaultPath = useMemo(() => {
    if (!permissions) return null;
    if (permissions.dashboard) {
      return PAGES.dashboard.path;
    }
    if (permissions.managePayroll) {
      return PAGES.platinumPayExpress.path;
    }
    if (permissions.runReports) {
      return PAGES.reports.path;
    }
    if (permissions.manageContactsAndUsers) {
      return PAGES.settingsUsers.path;
    }
    if (permissions.payrollFunding) {
      return PAGES.payrollFunding.path;
    }
    if (permissions.powerImports) {
      return PAGES.powerImports.path;
    }
    if (permissions.Onboarding) {
      return `${PAGES.onboarding.path}/setup`;
    }
    if (permissions?.employee) {
      return `${PAGES.employees.path}`;
    }
    if (profile?.swipeClockLoginName) {
      return PAGES.swipeClock.path;
    }
    return null;
  }, [permissions]);

  const validatePathPermission = async (profile) => {
    let _profile = profile;

    await delay(50);
    if (_profile !== profileRef.current) {
      return;
    }

    const pushLog = (message) => logErrorsService.error(`${message}. Path: ${path}`);

    if (!initiated) {
      setRouteProps();
    } else if (authService.isCpaUser()) {
      setRouteProps({ type: "route" });
    } else if (authService.isEssUser()) {
      setRouteProps({ type: "route" });
    } else if (authService.isIpRestricted() && location.pathname !== PAGES.restrictedIp.path) {
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: PAGES.restrictedIp.path,
            state: { prevLocation: locationName, error: "ip restricted" },
          },
        },
      });
    } else if (!authService.isCompanyAuthenticated() && !authService.isUserAuthenticated()) {
      pushLog("user is not authenticated at all");
      authService.logToken();
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: PAGES.brandsLogin.path,
            state: { prevLocation: locationName, error: "Not logged in" },
          },
        },
      });
    } else if (!userOnlyAuthenticated && !authService.isCompanyAuthenticated()) {
      pushLog("user is not authenticated to any company");
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: PAGES.brandsLogin.path,
            state: { prevLocation: locationName, error: "Not logged in" },
          },
        },
      });
    } else if (userOnlyAuthenticated && authService.isUserAuthenticated()) {
      setRouteProps({ type: "route" });
    } else if (!profile) {
      pushLog("user profile is null");
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: PAGES.brandsLogin.path,
            state: { prevLocation: locationName, error: "Not logged in" },
          },
        },
      });
    } else if (!permissions && defaultPath) {
      pushLog("user permissions array is null");
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: defaultPath,
            state: { prevLocation: locationName, error: "Not logged in" },
          },
        },
      });
    } else if (!profile?.permission) {
      pushLog("user does not have enough permissions");
      if (!defaultPath && locationName === PAGES.dashboard.path) {
        authService.signOut();
      }
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: defaultPath,
            state: { prevLocation: locationName, error: "Not authorized" },
          },
        },
      });
    } else if (permission) {
      setRouteProps({ type: "route" });
    } else if (!permission) {
      setRouteProps({
        type: "redirect",
        props: {
          to: {
            pathname: defaultPath,
            state: { prevLocation: locationName, error: "Not authorized" },
          },
        },
      });
    }
  };

  useEffect(() => {
    profileRef.current = profile;
    validatePathPermission(profile);
  }, [
    initiated,
    location,
    path,
    defaultPath,
    permissions,
    permission,
    profile,
    locationName,
    userOnlyAuthenticated,
  ]);

  useEffect(() => {
    if (disabled && path) {
      logErrorsService.error(`User conum or selectedCpaFirmId are not set. Path: ${path}`);
    }
  }, [disabled, path]);

  if (disabled) {
    return null;
  }

  if (!routeProps) {
    return null;
  }

  if (routeProps.type === "redirect" && routeProps.props?.to?.pathname !== location.pathname) {
    return <Redirect {...routeProps.props} />;
  }

  if (!permission && !userOnlyAuthenticated) {
    logErrorsService.warning("displaying unauth div.", { path, defaultPath });
    return (
      <div className="center-screen">
        Access to the requested resource at {path} is unauthorized. You are being redirected to{" "}
        {defaultPath} as a default destination. Please contact the administrator or support team if
        you believe you should have access to this resource.
      </div>
    );
  }

  return (
    <Route
      path={path}
      render={render ?? (() => (Component ? <Component title={title} /> : null))}
    />
  );
};

export default ProtectedRoute;
