import { updateFilterCalendarPeriods } from "../../utils/employeePayHistory";
import { CALENDAR_PERIODS } from "../../constants/employeePayHistory";

// ACTION TYPES
const TYPES = {
  SET_CUSTOM_PERIOD: "SET_CUSTOM_PERIOD",
  SET_CALENDAR_PERIOD: "SET_CALENDAR_PERIOD"
};

// ACTION CREATORS
export const setCustomPeriodAC = period => ({
  type: TYPES.SET_CUSTOM_PERIOD,
  payload: { period }
});

export const setCalendarPeriodAC = currentCalendarPeriod => ({
         type: TYPES.SET_CALENDAR_PERIOD,
         payload: { currentCalendarPeriod }
       });

// REDUCER
const initialState = {
  period: updateFilterCalendarPeriods(
    { calendarPeriod: CALENDAR_PERIODS.THIS_YEAR.id },
    new Date()
  ),
  currentCalendarPeriod: CALENDAR_PERIODS.THIS_YEAR.id
};

export const payhistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_CUSTOM_PERIOD:
      return {
        ...state,
        period: action.payload.period
      };
    case TYPES.SET_CALENDAR_PERIOD:
      return {
        ...state,
        currentCalendarPeriod: action.payload.currentCalendarPeriod
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectPeriod = state => state.payhistory.period;
export const selectCalendarPeriod = state => state.payhistory.currentCalendarPeriod;
