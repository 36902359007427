import addCpaIcon from '~/assets/NavBarIcons/add-cpa-company.svg'
import cpaDashboard from '~/assets/NavBarIcons/cpa_dashboard.svg'
import dashboardIcon from '~/assets/NavBarIcons/dashboard_icon.svg'
import payrollIcon from '~/assets/NavBarIcons/dollar.svg'
import feedbackIcon from '~/assets/NavBarIcons/feedback_icon.svg'
import reportsIconV2 from '~/assets/NavBarIcons/folder.svg'
import helpCenterIcon from '~/assets/NavBarIcons/help_center.svg'
import manageHrIcon from '~/assets/NavBarIcons/manage_hr_icon.svg'
import manageIcon from '~/assets/NavBarIcons/manage_icon.svg'
import payrollFinancingIcon from '~/assets/NavBarIcons/payroll_financing_icon.svg'
import powerImportIcon from '~/assets/NavBarIcons/power_import_icon.svg'
import servicesIcon from '~/assets/NavBarIcons/services.svg'
import settings from '~/assets/NavBarIcons/settings-gear-icon.svg'
import settingsIcon from '~/assets/NavBarIcons/settings.svg'
import swipeClockIcon from '~/assets/NavBarIcons/swipe_clock_icon.svg'
import employeesIcon from '~/assets/NavBarIcons/users.svg'

interface PageConfig {
  id: string
  path: string
  title?: string
  iconSrc?: string
}

export const PAGES = {
  authConfirmAccount: {
    id: 'authConfirmAccount',
    title: 'Confirm Account',
    path: '/auth/confirm-account',
  },
  brandsLogin: {
    id: 'brandsLogin',
    title: 'Brands Login',
    path: '/auth/login',
  },
  loginVerifyPassword: {
    id: 'loginVerifyPassword',
    title: 'Enter Your Password',
    path: '/verify_password',
  },
  brandsLoginSelectCompany: {
    id: 'loginSelectCompany',
    title: 'Please Select a Company',
    path: '/auth/select-company',
  },
  impersonate: {
    id: 'impersonate',
    title: 'Log out',
    path: '/auth/logout',
  },

  setUpAccountPassword: {
    id: 'setUpAccountPassword',
    title: 'SetUp Account: Password',
    path: '/auth/set-up-account/password',
  },

  resetPasswordEmail: {
    id: 'resetPasswordEmail',
    title: 'Reset Password: Email Authentication',
    path: '/auth/reset-password/email',
  },
  resetPasswordInput: {
    id: 'resetPasswordInput',
    title: 'Reset Password: Input',
    path: '/auth/reset-password/input',
  },
  resetPasswordComplete: {
    id: 'resetPasswordComplete',
    title: 'Reset Password: Complete',
    path: '/auth/reset-password/complete',
  },
  twoFactorAuthLogin: {
    id: 'twoFactorAuthLogin',
    title: 'Two Factor Authentication',
    path: '/auth/login/mfa',
  },
  authFrillSso: {
    id: 'authFrillSso',
    title: 'Frill SSO',
    path: '/auth/frillSso',
  },

  authorizePayroll: {
    id: 'authorizePayroll',
    title: 'Authorize Payroll',
    path: '/authorize-payroll',
  },

  restrictedIp: {
    id: 'restrictedIp',
    path: '/restricted',
  },

  employeeForm: {
    id: 'employeeForm',
    title: 'Employee Form',
    path: '/employee-form',
  },

  // DASHBOARD
  dashboard: {
    id: 'Dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    iconSrc: dashboardIcon,
  },
  dashboardOld: {
    id: 'dashboardOld',
    title: 'Dashboard Old',
    path: '/dashboardOld',
  },
  // EMPLOYEES
  payHistoryEmployee: {
    id: 'payHistoryEmployee', // Must come before payHistory
    title: 'Employee Pay History',
    path: '/pay-history/employee',
  },
  payrollFinancing: {
    id: 'payrollFinancing',
    title: 'Payroll Financing',
    path: '/payroll-financing',
    iconSrc: payrollFinancingIcon,
  },
  employeesOnboarding: {
    id: 'employeesOnboarding',
    title: 'Onboarding',
    path: '/employees-onboarding',
  },
  employees: {
    id: 'employees',
    title: 'Employees',
    path: '/employees',
    iconSrc: employeesIcon,
  },
  newEmployees: {
    id: 'new-employee',
    title: 'Add new employee',
    path: '/employees-new',
  },
  usersbyCompany: {
    id: 'usersbyCompany',
    title: 'Users by company',
    path: '/usersbycompany',
  },

  // USERS
  settings: {
    id: 'settingsUsers',
    title: 'Settings',
    path: '/settings/users',
    iconSrc: settingsIcon,
  },

  settingsUsers: {
    id: 'settingsUsers',
    title: 'Users & Roles',
    path: '/settings/users',
  },

  settingsManageCpaPortal: {
    id: 'settingsManageCpaPortal',
    title: 'CPA Portal',
    path: '/settings/cpaportal',
  },

  settingsBankAccounts: {
    id: 'settingsBankAccounts',
    title: 'Bank Account(s)',
    path: '/settings?option=bankAccounts',
  },

  settingsIpRestrictions: {
    id: 'settingsIpRestrictions',
    title: 'IP Restrictions',
    path: '/settings/ip-restrictions',
  },

  settingsNotifications: {
    id: 'settingsNotifications',
    title: 'Notifications',
    path: '/settings/notifications',
  },

  // EMPLOYEE DECK SETTINGS
  employeeDeckSettings: {
    id: 'employee-deck-settings',
    title: 'Employee Deck Settings',
    path: '/settings/employeedeck',
  },

  settingsMain: {
    id: 'settingsMain',
    title: 'Settings',
    path: '/settings',
  },

  // SERVICES
  services: {
    id: 'services',
    title: 'Services & Apps',
    path: '/services',
    iconSrc: servicesIcon,
  },

  // REPORTS
  reportsPrimary: {
    id: 'reportsPrimary',
    title: 'Primary Reports',
    path: '/reports/primary',
  },
  standardReportsOld: {
    id: 'standardReports',
    title: 'Archived Reports',
    path: '/standard_reports',
  },
  reportsRun: {
    id: 'reportsRun',
    title: 'Run a Report',
    path: '/reports/run',
    iconSrc: reportsIconV2,
  },
  reports: {
    id: 'reports',
    title: 'Reports',
    path: '/reports',
    iconSrc: reportsIconV2,
  },
  reportsRunV2: {
    id: 'reportsRun',
    title: 'Run a Report',
    path: '/reports/run-v2',
    iconSrc: reportsIconV2,
  },
  liveReports: {
    id: 'liveReports',
    title: 'Live Reports',
    path: '/reports/live',
    iconSrc: reportsIconV2,
  },
  runReportOld: {
    id: 'runReport',
    title: 'Reports on Demand',
    path: '/run_report',
  },
  reportsPosted: {
    id: 'reportsPosted',
    title: 'Posted Reports',
    path: '/reports/posted',
  },
  myReportsOld: {
    id: 'myReports',
    title: 'My Reports',
    path: '/my_reports',
  },
  newReport: {
    id: 'newReport',
    title: 'Data Studio / Untitled Report',
    path: '/new_report',
  },
  supportOvertime: {
    id: 'supportOvertime',
    title: 'Data Studio / Support Dep',
    path: '/support_overtime',
  },
  helpCenter: {
    id: 'helpCenter',
    title: 'Help Center',
    path: 'https://support.brandspaycheck.com/hc/en-us',
    iconSrc: helpCenterIcon,
  },
  feedback: {
    id: 'feedback',
    title: 'Client Feedback',
    path: '/feedback',
    iconSrc: feedbackIcon,
  },
  platinumPayExpress: {
    id: 'Manage Payroll',
    title: 'Payroll (PPX Legacy)',
    path: '/platinum-pay-express',
    iconSrc: manageIcon,
  },
  swipeClock: {
    id: 'swipeClock',
    title: 'Timekeeping',
    path: '/swipe-clock',
    iconSrc: swipeClockIcon,
  },
  hrNextDirect: {
    id: 'hrNextDirect',
    title: 'Hr Next Direct',
    path: '/hrNextDirect',
    iconSrc: manageHrIcon,
  },
  // POWER IMPORTS
  powerImports: {
    id: 'powerImport',
    title: 'Power Imports',
    path: '/powerImports',
    iconSrc: powerImportIcon,
  },
  // ERRORS
  testReactErrorBoundaries: {
    id: 'testReactErrorBoundaries',
    path: '/test_react_error_boundaries',
  },
  // ONBOARDING
  onboarding: {
    id: 'onboarding',
    title: 'Onboarding',
    path: '/onboarding',
  },
  onboardingSetup: {
    id: 'onboardingSetup',
    title: 'Onboarding',
    path: '/onboarding/setup',
  },
  onboardingNew: {
    id: 'onboardingNew',
    title: 'Onboarding',
    path: '/onboarding/new',
  },
  // EMPLOYEE DECK USERS
  employeeDeckUsers: {
    id: 'employee-deck-users',
    title: 'Employee Deck Users',
    path: '/employee-deck/users',
  },
  // CPA PORTAL
  cpaClients: {
    id: 'cpa-clients',
    title: 'CPA Clients',
    path: '/cpa/clients',
    iconSrc: cpaDashboard,
  },
  cpaSettings: {
    id: 'cpa-settings',
    title: 'CPA Settings',
    path: '/cpa/settings',
    iconSrc: settings,
  },
  cpaSignup: {
    id: 'cpa-signup',
    title: 'CPA Signup',
    path: '/cpa/signup',
  },
  cpaProfile: {
    id: 'cpa-profile',
    title: 'CPA Profile',
    path: '/cpa/profile',
  },
  cpaReports: {
    id: 'cpa-reports',
    title: 'CPA Reports',
    path: '/cpa/reports',
    iconSrc: reportsIconV2,
  },
  cpaClientOverview: {
    id: 'cpa-client-overview',
    title: 'Overview',
    path: '/cpa/overview',
    iconSrc: cpaDashboard,
  },
  cpaClientSettings: {
    id: 'cpa-client-settings',
    title: 'Settings',
    path: '/cpa/settings',
    iconSrc: settings,
  },
  cpaAddCompany: {
    id: 'cpa-add',
    title: 'CPA Add Company',
    path: '/cpa/add',
    iconSrc: addCpaIcon,
  },
  //QBO
  qboConnect: {
    id: 'qbo-connect',
    title: 'QBO Connect',
    path: '/qbo/connect',
  },
  payrollsPowerImports: {
    id: 'payrollsPowerImports',
    title: 'Power Imports',
    path: '/payrolls/power-imports',
  },
  payrollsManualChecks: {
    id: 'payrollsManualChecks',
    title: 'Manual & Voided Checks',
    path: '/payrolls/manual-checks',
  },
  payrollsPreferences: {
    id: 'payrollsPreferences',
    title: 'Preferences',
    path: '/payrolls/preferences',
  },
  payrollsList: {
    id: 'payrollsList',
    title: 'Preferences - Payroll Lists',
    path: '/payrolls/preferences/lists',
  },
  newPayrollsList: {
    id: 'newPayrollsList',
    title: 'Preferences - Payroll Lists - New',
    path: '/payrolls/preferences/new-list',
  },
  editPayrollsList: {
    id: 'editPayrollsList',
    title: 'Preferences - Payroll Lists - Edit',
    path: '/payrolls/preferences/edit-list',
  },
  payrolls: {
    id: 'payrolls',
    title: 'Payrolls',
    path: '/payrolls',
    iconSrc: payrollIcon,
  },
  payrollsOverview: {
    id: 'payrollsOverview',
    title: 'Overview',
    path: '/payrolls',
  },
  payrollStart: {
    id: 'payrollStart',
    title: 'Start Payroll',
    path: '/payrolls/new',
  },
  payrollStartEmployees: {
    id: 'payrollStart',
    title: 'Start Payroll - Select Employees',
    path: '/payrolls/new/start/employees',
  },
  payrollStartManualEmployees: {
    id: 'payrollStart',
    title: 'Start Payroll - Select Employees',
    path: '/payrolls/new/start/employees/manual',
  },
  payrollStartSubmit: {
    id: 'payrollSubmit',
    title: 'Start Payroll - Submit',
    path: '/payrolls/new/start/submit',
  },
  // ESS
  essDashboard: {
    id: 'essDashboard',
    title: 'Dashboard',
    path: '/ess/dashboard',
  },
  essPayHistory: {
    id: 'essPayHistory',
    title: 'Pay History',
    path: '/ess/pay-history',
  },
  essPayHistoryDetail: {
    id: 'essPayHistoryDetail',
    title: 'Pay History Detail',
    path: '/ess/pay-history/:id',
  },
  essProfile: {
    id: 'essProfile',
    title: 'Profile',
    path: '/ess/profile',
  },
  essDocuments: {
    id: 'essDocuments',
    title: 'Documents',
    path: '/ess/documents',
  },
  essTaxWithholdings: {
    id: 'essTaxWithholdings',
    title: 'Tax withholdings - W4',
    path: '/ess/tax-withholdings',
  },
  essDirectDeposit: {
    id: 'essDirectDeposit',
    title: 'Direct Despoit',
    path: '/ess/direct-deposit',
  },
  essTimeKeeping: {
    id: 'essTimeKeeping',
    title: 'Time Keeping',
    path: '/ess/time-keeping',
  },
  essPosterElite: {
    id: 'essPostElite',
    title: 'Poster Elite',
    path: '/ess/posterElite',
  },
  devextremeEmployees: {
    id: 'devextremeEmployees',
    title: 'Employees',
    path: '/devextreme/employees',
  },
  essSetup: {
    id: 'essSetup',
    title: 'Setup',
    path: '/ess/setup',
  },
  employeeOnboarding: {
    id: 'employeeOnboarding',
    title: 'Employee Onboarding',
    path: '/employee-onboarding',
  },
  designGuidelines: {
    id: 'designGuidelines',
    title: 'Design Guidelines',
    path: '/design-guidelines',
  },
  customReports: {
    id: 'customReports',
    title: 'Custom Reports',
    path: '/custom-reports',
  },
} satisfies Record<string, PageConfig>
