import { useEffect } from 'react'

import { Avatar, Backdrop, Box, Button, CircularProgress, Dialog, ThemeProvider, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { AlertTriangleIcon } from '@/components/ui/icons/alert-triangle'
import { CheckCircleIcon } from '@/components/ui/icons/check-circle'
import { InfoCircleIcon } from '@/components/ui/icons/info-circle'
import { theme } from '@/lib/theme'
import { useAppState } from '@/states/app-state'

import { DialogTransition } from './dialog-transition'
import { SnackbarCloseAction } from './snackbar-close-action'

interface Props {
  children: React.ReactNode
}

export function AppProvider({ children }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const { isLoading, messageDialogOptions, hideMessageDialog, messageToastOptions } = useAppState()

  useEffect(() => {
    if (!messageToastOptions) return

    enqueueSnackbar(messageToastOptions.message, {
      variant: messageToastOptions.variant,
      autoHideDuration: 4000,
      action: (id) => <SnackbarCloseAction snackbarId={id} />,
    })
  }, [enqueueSnackbar, messageToastOptions])

  const isMessageDialogOpen = !!messageDialogOptions

  return (
    <>
      {children}
      <ThemeProvider theme={theme}>
        <Backdrop open={isLoading} sx={{ zIndex: 2000 }}>
          <CircularProgress disableShrink />
        </Backdrop>
        <Dialog
          open={isMessageDialogOpen}
          keepMounted
          TransitionComponent={DialogTransition}
          onClose={() => hideMessageDialog()}
          sx={{
            '& .MuiDialog-paper': {
              borderWidth: '2px 0 0 0',
              borderTopColor: `${messageDialogOptions?.variant}.light`,
            },
          }}
        >
          {messageDialogOptions && (
            <Box display="flex" flexDirection="column" gap={2} p={2}>
              <Avatar sx={{ bgcolor: `${messageDialogOptions.variant}.main`, width: 44, height: 44 }}>
                {messageDialogOptions.variant === 'warning' && <AlertTriangleIcon sx={{ color: 'common.white' }} />}
                {messageDialogOptions.variant === 'success' && <CheckCircleIcon sx={{ color: 'common.white' }} />}
                {(messageDialogOptions.variant === 'info' || messageDialogOptions.variant === 'error') && (
                  <InfoCircleIcon sx={{ color: 'common.white' }} />
                )}
              </Avatar>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="h6">{messageDialogOptions.title}</Typography>
                <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
                  {messageDialogOptions.message}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button variant="outlined" color="inherit" size="small" onClick={() => hideMessageDialog()}>
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </Dialog>
      </ThemeProvider>
    </>
  )
}
