import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

import { createTheme } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { buttonClasses } from '@mui/material/Button'
import { formLabelClasses } from '@mui/material/FormLabel'
import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { tabClasses } from '@mui/material/Tab'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties
    body4: React.CSSProperties
    subtitle3: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
    body4?: React.CSSProperties
    subtitle3?: React.CSSProperties
  }

  interface TypographyStates {
    hover: string
    selected: string
    focus: string
    focusVisible: string
    outlinedBorder?: string
  }

  interface Palette {
    textStates: TypographyStates
    primaryStates: TypographyStates
    errorStates: TypographyStates
    border?: TypeBorder
    purple: Palette['primary']
    green: Palette['grey']
  }

  interface PaletteOptions {
    textStates?: TypographyStates
    primaryStates?: TypographyStates
    errorStates?: TypographyStates
    border?: TypeBorder
    purple?: PaletteOptions['primary']
    green?: PaletteOptions['grey']
  }

  interface TypeBackground {
    success?: string
    error?: string
    warning?: string
    info?: string
    tableHeader?: string
  }

  interface TypeBorder {
    success?: string
    error?: string
    warning?: string
    info?: string
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    body4: true
    subtitle3: true
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    scrollbar?: CSSProperties
    scrollbarLight?: CSSProperties
    scrollbarLarge?: CSSProperties
    drawerWidth?: number
    navBarHeight?: number
  }
}

declare module '@mui/material/Avatar' {
  interface AvatarOwnProps {
    size?: 'small' | 'medium' | undefined
  }
}

declare module '@mui/material/ListItemButton' {
  interface ListItemButtonOwnProps {
    variant?: 'bolded' | undefined
  }
}

const basePaletteTheme = createTheme({
  palette: {
    grey: {
      50: '#F6F6F6',
      100: '#E7E7E7',
      200: '#D1D1D1',
      300: '#B0B0B0',
      400: '#898989',
      500: '#6D6D6D',
      600: '#5D5D5D',
      700: '#4F4F4F',
      800: '#454545',
      900: '#3D3D3D',
    },
  },
})

export const theme = createTheme({
  shape: {
    borderRadius: 6,
  },
  mixins: {
    scrollbar: {
      '& ::-webkit-scrollbar': {
        width: 6,
      },
      '& ::-webkit-scrollbar:horizontal': {
        height: 6,
      },
      '& ::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        borderRadius: 2.5,
        border: '3px solid transparent',
        backgroundClip: 'padding-box',
      },
      '&:hover': {
        '& ::-webkit-scrollbar-thumb': {
          border: '3px solid #000000',
        },
      },
      scrollbarWidth: 'thin',
      scrollbarColor: 'transparent',
    },
    scrollbarLight: {
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 2,
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        borderRadius: 20,
        border: '3px solid transparent',
        backgroundClip: 'padding-box',
      },
      '&:hover': {
        '&::-webkit-scrollbar': {
          width: 2,
          marginTop: 3,
        },
        '&::-webkit-scrollbar-thumb': {
          border: '3px solid #c4c4c4',
        },
      },
    },
    scrollbarLarge: {
      '& ::-webkit-scrollbar': {
        width: 12,
      },
      '& ::-webkit-scrollbar:horizontal': {
        height: 12,
      },
      '& ::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        borderRadius: 5,
        border: '6px solid #000000',
        backgroundClip: 'padding-box',
      },
      '&:hover': {
        '& ::-webkit-scrollbar-thumb': {
          border: '6px solid #000000',
        },
      },
      scrollbarWidth: 'thin',
      scrollbarColor: 'transparent',
    },
    drawerWidth: 260,
    navBarHeight: 48,
  },
  typography: {
    h1: {
      fontFamily: '"Merriweather", serif',
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '56px',
      letterSpacing: 0,
    },
    h2: {
      fontFamily: '"Merriweather", serif',
      fontWeight: 700,
      fontSize: '34px',
      lineHeight: '42px',
      letterSpacing: 0,
    },
    h3: {
      fontFamily: '"Merriweather", serif',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '34px',
      letterSpacing: 0,
    },
    h4: {
      fontFamily: '"Inter", serif',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '38px',
      letterSpacing: 0,
    },
    h5: {
      fontFamily: '"Inter", serif',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '30px',
      letterSpacing: 0,
    },
    h6: {
      fontFamily: '"Inter", serif',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: 0,
    },
    body1: {
      fontFamily: '"Inter", serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 0,
    },
    body2: {
      fontFamily: '"Inter", serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0,
    },
    body3: {
      fontFamily: '"Inter", serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: 0,
    },
    body4: {
      fontFamily: '"Inter", serif',
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: '"Inter", serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: '"Inter", serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0,
    },
    subtitle3: {
      fontFamily: '"Inter", serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0,
    },
    overline: {
      fontFamily: '"Inter", serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"Inter", serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.02em',
    },
    button: {
      fontFamily: '"Inter", serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0,
      textTransform: 'none',
    },
  },
  palette: {
    text: {
      primary: basePaletteTheme.palette.grey[900],
      secondary: basePaletteTheme.palette.grey[500],
      disabled: basePaletteTheme.palette.grey[300],
    },
    textStates: {
      hover: '#3D3D3D0A',
      selected: '#3D3D3D14',
      focus: '#3D3D3D1F',
      focusVisible: '#3D3D3D4D',
    },
    primary: {
      main: '#4C27FF',
      light: '#877FFF',
      dark: '#3311C6',
      contrastText: '#FFFFFF',
    },
    primaryStates: {
      hover: '#4C27FF0A',
      selected: '#4C27FF14',
      focus: '#4C27FF1F',
      focusVisible: '#4C27FF40',
      outlinedBorder: '#4C27FF80',
    },
    secondary: {
      main: '#0FA7E8',
      light: '#7ED4FB',
      dark: '#046AA0',
      contrastText: '#FFFFFF',
    },
    purple: {
      light: '#AFADFF',
      main: '#4426D9',
      dark: '#4426D9',
    },
    action: {
      active: '#0000008F',
      hover: '#0000000A',
      selected: '#00000014',
      focus: '#0000001F',
      disabled: '#00000061',
      disabledBackground: '#0000001F',
    },
    error: {
      main: '#E91A19',
      light: '#FF6867',
      dark: '#A21312',
      contrastText: '#FFFFFF',
    },
    errorStates: {
      hover: 'rgba(233, 26, 25, 0.04)',
      selected: '#E91A19',
      focus: '#E91A19',
      focusVisible: '#E91A19',
      outlinedBorder: '#E91A19',
    },
    warning: {
      main: '#EF6C00',
      light: '#FF9800',
      dark: '#E65100',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#4C27FF',
      light: '#877FFF',
      dark: '#3311C6',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#04B50B',
      light: '#45F24B',
      dark: '#0C6F11',
      contrastText: '#FFFFFF',
    },
    common: {
      black: basePaletteTheme.palette.grey[900],
      white: '#FFFFFF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FBFBFF',
      success: '#EFFFEE',
      error: '#FFF1F1',
      warning: '#FFF3E0',
      info: '#F0F9FF',
      tableHeader: 'rgba(61, 61, 61, 0.04)',
    },
    green: {
      900: '#0C5B11',
    },
    divider: '#E7E7E7',
    grey: {
      ...basePaletteTheme.palette.grey,
    },
    border: {
      success: '#04B50B80',
      error: '#E91A1980',
      warning: '#EF6C0080',
      info: '#0FA7E880',
    },
  },
  shadows: [
    'none',
    '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
    '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          padding: theme.spacing(1.25, 2),
          borderRadius: 6,
        }),
        sizeLarge: ({ theme }) => ({
          padding: theme.spacing(1, 2.75),
          fontSize: 15,
          lineHeight: '24px',
          [`&.${buttonClasses.outlined}`]: {
            padding: theme.spacing(0.875, 2.625),
          },
        }),
        sizeMedium: ({ theme }) => ({
          padding: theme.spacing(1, 2),
          [`&.${buttonClasses.outlined}`]: {
            padding: theme.spacing(0.875, 1.875),
          },
        }),
        sizeSmall: ({ theme }) => ({
          padding: theme.spacing(0.875, 1.5),
          fontSize: 13,
          lineHeight: '18px',
          [`&.${buttonClasses.outlined}`]: {
            padding: theme.spacing(0.75, 1.375),
          },
          [`& .${buttonClasses.endIcon}`]: {
            marginLeft: theme.spacing(0.75),
          },
          [`& .${buttonClasses.endIcon} > *:nth-of-type(1)`]: {
            fontSize: 14,
          },
        }),
        startIcon: {
          '>*:nth-of-type(1)': { fontSize: 16 },
        },
        endIcon: {
          '>*:nth-of-type(1)': { fontSize: 16 },
        },
        contained: {
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
          '&:hover': {
            boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
          },
          '&:focus': {
            boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          },
          [`&.${buttonClasses.colorPrimary} .${svgIconClasses.root}`]: {
            stroke: '#FFF !important',
          },
        },
        colorInherit: () => ({
          // borderColor: theme.palette.action.focus,
        }),
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
        outlined: ({ theme }) => ({
          border: `1px solid ${theme.palette.action.hover}`,
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(3),
        }),
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          sx: {
            backgroundColor: 'primaryStates.outlinedBorder',
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          width: '100%',
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          [`&.${tabClasses.selected}`]: {
            backgroundColor: theme.palette.primaryStates.hover,
          },
        }),
      },
    },
    MuiPopover: {
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 2,
            variant: 'elevation',
          },
        },
      },
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiModal: {
      defaultProps: {
        slotProps: {
          backdrop: {
            invisible: false,
          },
        },
      },
      styleOverrides: {
        backdrop: () => ({
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          [`&.${listItemButtonClasses.root}`]: {
            padding: theme.spacing(1.5, 2),
            ...(ownerState.variant === 'bolded' && {
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
              },
              '&.Mui-selected': {
                color: theme.palette.text.primary,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            }),
          },
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...theme.typography.caption,
          boxShadow: theme.shadows[1],
          width: 64,
          height: 64,
          ...(ownerState.size === 'small' && {
            width: 24,
            height: 24,
          }),
        }),
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body2',
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          // minHeight: 24,
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(0.25),
          justifyContent: 'center',
        }),
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: () => ({
          minWidth: 36,
          display: 'flex',
          alignItems: 'center',
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${outlinedInputClasses.root}`]: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            [`& .${autocompleteClasses.input}`]: {
              padding: theme.spacing(1, 1.5),
            },
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.caption,
        }),
        sizeSmall: () => ({
          height: 18,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          fontWeight: 400,
          '&.MuiInput-underline': {
            '&:before': {
              borderWidth: 1,
              borderBottomColor: theme.palette.divider,
            },
            '&:after': {
              borderWidth: 1,
              borderBottomColor: theme.palette.grey[900],
            },
            '&:hover:not(.Mui-disabled,.Mui-error):before': {
              borderWidth: 1,
              borderBottomColor: theme.palette.divider,
            },
          },
          '& .MuiInputBase-inputAdornedStart': {
            paddingLeft: 0,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: ({ theme }) => ({
          marginRight: theme.spacing(0.75),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.125),
          [`& .${svgIconClasses.root}`]: {
            width: 20,
            height: 20,
          },
        }),
      },
    },
    // TODO: need review ☝️
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(0.75),
        }),
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            variant: 'body2',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          ...theme.typography.body2,
          [`&.${formLabelClasses.focused}`]: {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textWrap: 'wrap',
        },
        outlined: {
          position: 'relative',
          transform: 'translate(0px, 0px) scale(1)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(0.75),
        }),
      },
    },
    MuiDateRangePicker: {
      defaultProps: {
        slotProps: {
          fieldSeparator: {
            sx: { display: 'none' },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 1.5),
          [`&.${outlinedInputClasses.disabled}`]: {
            backgroundColor: theme.palette.action.hover,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.grey[100],
            },
          },
        }),
        input: {
          padding: 0,
        },
        notchedOutline: {
          top: 0,
          legend: {
            display: 'none',
          },
        },
        error: ({ theme }) => ({
          backgroundColor: theme.palette.errorStates.hover,
        }),
      },
    },
  },
})
