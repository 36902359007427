import { AxiosError } from 'axios'

interface ValidationError {
  errorCode: string
  errorMessage: string
  propertyName: string
}

export function getErrorMessage(error: unknown) {
  let message = ''

  if (error instanceof AxiosError) {
    if (error.response) {
      const data = error.response.data as Record<string, unknown> | string | undefined

      if (typeof data === 'string') {
        message = data
      } else if (data?.title) {
        message = data.title as string
      } else if (data?.message) {
        message = data.message as string
      } else if (data?.errors) {
        const errors = data.errors as ValidationError[]
        message = errors.map((e) => e.errorMessage).join('\n')
      }
    } else {
      message = error.message
    }
  } else if (error instanceof Error) {
    message = error.message
  } else if (typeof error === 'string') {
    message = error
  }

  return message || 'An error occurred. Please try again.'
}
