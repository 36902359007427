import { flattenObject } from 'es-toolkit'

export function createFormData(body: Record<string, unknown>) {
  const formData = new FormData()

  for (const [key, value] of Object.entries(flattenObject(body))) {
    if (value !== null && value !== undefined) {
      formData.append(key.replace(/\.([0-9]+)(?=\.|$)/g, '[$1]'), value as string | Blob)
    }
  }

  return formData
}
