export const IS_PRODUCTION = process.env.REACT_APP_ENV !== "development";

const constants = {
  // if production use "" HOST_URL, otherwise use REACT_APP_HOST_URL from .env file
  HOST_URL: IS_PRODUCTION ? "" : process.env.REACT_APP_HOST_URL || "",
  //HOST_URL: "https://brandswebapp-api.azurewebsites.net",
  JWT_TOKEN: "BrandsWebJWT",
  JWT_GUID: "BrandsWebJWTGuid",
  APP_VERSION: process.env.REACT_APP_VERSION,
  IS_PRODUCTION,
  GUID: "OnboardingGuid",
  CALENDLY_URI: "https://calendly.com/brandscustomersupport/paydeckonboarding",
  DONT_SHOW_REPORT_CONFIRM: "BrandsDontShowReportConfirm",
  REMEMBER_BROWSER_GUID: "RememberBrowserGuid",
  EMPLOYEE_ID: "empnum",
  BFP: "bfp",
  SFP: "sfp",
  EMPLOYEE_TABLE_COLUMNS_MODEL: "employee_table_columns_model",
  LIVE_REPORTS_COLUMNS: "LIVE_REPORTS_COLUMNS",
  LIVE_REPORTS_GRID_LAYOUT: "LIVE_REPORTS_GRID_LAYOUT",
  IS_NATIVE: "BrandsWebIsNative",
};

export default constants;
