// ACTION TYPES
const TYPES = {
  ESS_FETCH_INVITES_SAGA: "ESS_FETCH_INVITES_SAGA",
  ESS_FETCH_INVITES_SUCCESS: "ESS_FETCH_INVITES_SUCCESS",
  ESS_FETCH_INVITES_FAILURE: "ESS_FETCH_INVITES_FAILURE",

  ESS_SEND_INVITES_SAGA: "ESS_SEND_INVITES_SAGA",
  ESS_SEND_INVITES_SUCCESS: "ESS_SEND_INVITES_SUCCESS",
  ESS_SEND_INVITES_FAILURE: "ESS_SEND_INVITES_FAILURE",

  ESS_SEND_REMINDERS_SAGA: "ESS_SEND_REMINDERS_SAGA",
  ESS_SEND_REMINDERS_SUCCESS: "ESS_SEND_REMINDERS_SUCCESS",
  ESS_SEND_REMINDERS_FAILURE: "ESS_SEND_REMINDERS_FAILURE",

  ESS_UPDATE_INVITE_EMAIL_SAGA: "ESS_UPDATE_INVITE_EMAIL_SAGA",
  ESS_UPDATE_INVITE_EMAIL_SUCCESS: "ESS_UPDATE_INVITE_EMAIL_SUCCESS",
  ESS_UPDATE_INVITE_EMAIL_FAILURE: "ESS_UPDATE_INVITE_EMAIL_FAILURE",

  ESS_REPLACE_INVITES: "ESS_REPLACE_INVITES",

  ESS_GET_AUTO_INVITE_SETTING_SUCCESS: "ESS_GET_AUTO_INVITE_SETTING_SUCCESS",
  ESS_GET_AUTO_INVITE_SETTING_FAILURE: "ESS_GET_AUTO_INVITE_SETTING_FAILURE",

  ESS_SET_AUTO_INVITE_SETTING_SAGA: "ESS_SET_AUTO_INVITE_SETTING_SAGA",
  ESS_SET_AUTO_INVITE_SETTING_SUCCESS: "ESS_SET_AUTO_INVITE_SETTING_SUCCESS",
  ESS_SET_AUTO_INVITE_SETTING_FAILURE: "ESS_SET_AUTO_INVITE_SETTING_FAILURE",

  ESS_SET_AUTO_INVITE_EXCLUDED_SAGA: "ESS_SET_AUTO_INVITE_EXCLUDED_SAGA",
  ESS_SET_AUTO_INVITE_EXCLUDED_SUCCESS: "ESS_SET_AUTO_INVITE_EXCLUDED_SUCCESS",
  ESS_SET_AUTO_INVITE_EXCLUDED_FAILURE: "ESS_SET_AUTO_INVITE_EXCLUDED_FAILURE",

  ESS_RESCIND_INVITE_SAGA: "ESS_RESCIND_INVITE_SAGA",
  ESS_RESCIND_INVITE_SUCCESS: "ESS_RESCIND_INVITE_SUCCESS",
  ESS_RESCIND_INVITE_FAILURE: "ESS_RESCIND_INVITE_FAILURE",

  ESS_CLEAR_ERROR: "ESS_CLEAR_ERROR",

  ESS_CLEAR_ALL: "ESS_CLEAR_ALL"
};

// REDUCER
const initialState = {
  invites: [],
  inviteSessionCacheId: "",
  autoInvite: {
    autoInvite: false
  } /* Make an object so state will can be different on failure even if boolean value is the same in order to revert toggle switch. */
};

export const essReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.ESS_FETCH_INVITES_SUCCESS:
      return {
        ...state,
        invites: payload.invites,
        inviteSessionCacheId: payload.inviteSessionCacheId
      };
    case TYPES.ESS_FETCH_INVITES_FAILURE:
      return state;
    case TYPES.ESS_UPDATE_INVITE_EMAIL_SUCCESS:
      return {
        ...state,
        invites: state.invites.map(invite => {
          return invite.empnum === payload.empnum ? payload : invite;
        })
      };
    case TYPES.ESS_UPDATE_INVITE_EMAIL_FAILURE:
      return state;
    case TYPES.ESS_SEND_INVITES_SUCCESS:
      return {
        ...state,
        invites: state.invites.map(invite => {
          if (payload.checkedInvites.indexOf(invite.empnum) > -1) {
            invite.rejection = "Invited.";
          }
          return invite;
        })
      };
    case TYPES.ESS_SEND_INVITES_FAILURE:
      return state;
    case TYPES.ESS_SEND_REMINDERS_SUCCESS:
      return state; /* Invited status hasn't changed. */
    case TYPES.ESS_SEND_REMINDERS_FAILURE:
      return state;
    case TYPES.ESS_REPLACE_INVITES:
      return {
        ...state,
        invites: [...payload]
      };
    case TYPES.ESS_GET_AUTO_INVITE_SETTING_SUCCESS:
      return {
        ...state,
        autoInvite: payload
      };
    case TYPES.ESS_GET_AUTO_INVITE_SETTING_FAILURE:
      return state;
    case TYPES.ESS_SET_AUTO_INVITE_SETTING_SUCCESS:
      return {
        ...state,
        autoInvite: payload
      };
    case TYPES.ESS_SET_AUTO_INVITE_SETTING_FAILURE:
      return {
        ...state,
        autoInvite: payload /* Need to revert toggle switch */
      };
    case TYPES.ESS_SET_AUTO_INVITE_EXCLUDED_SUCCESS:
      return {
        ...state,
        invites: state.invites.map(invite => {
          return invite.empnum === payload.empnum ? payload : invite;
        })
      };
    case TYPES.ESS_SET_AUTO_INVITE_EXCLUDED_FAILURE:
      return state;
    case TYPES.ESS_CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case TYPES.ESS_CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};
