import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ErrorBoundary from "../containers/ErrorBoundary";
import { axiosError } from "../core";
import { useDispatch } from "react-redux";
import { loadingFinishAC, loadingStartAC } from "../redux/ducks/appDuck";
import { getErrorSnackbarOptions } from "./snackbarNotifications";
import { useSnackbar } from "notistack";

const AppUiContext = React.createContext({
  showConfirmation: (title, message) => {},
  showConfirmYesNo: (title, message) => {},
  showCustomConfirm: (title, customContent) => {},
  showErrorMessage: (ex, title) => {},
  showMessage: (title, message) => {},
  showCustomDialog: (title, customContent) => {},
  showLoading: () => {},
  hideLoading: () => {},
  showErrorSnackbar: (error) => {},
  showErrorStringSnackbar: (message) => {},
});

const AppUiProvider = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [data, setData] = useState({});
  const resolver = useRef();

  const handleShowLoading = () => {
    dispatch(loadingStartAC());
  };

  const handleHideLoading = () => {
    dispatch(loadingFinishAC());
  };

  const handleShowErrorSnackbar = (err) => {
    let message = axiosError.errorDescription(err);
    enqueueSnackbar(message, getErrorSnackbarOptions(closeSnackbar));
  };

  const handleShowErrorStringSnackbar = (message) => {
    enqueueSnackbar(message, getErrorSnackbarOptions(closeSnackbar));
  };

  const handleShow = (title, message) => {
    setData({ title, message, noBtn: "Ok" });
    setShowConfirmationModal(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (showConfirmationModal && event.key === "Enter") {
        event.preventDefault();
        handleOk();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [showConfirmationModal]);

  const confirm = (title, message, { yesBtn = "Yes", noBtn = "No" } = {}) => {
    setData({ title, message, yesBtn, noBtn });
    setShowConfirmationModal(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const showCustomConfirm = (title, customContent, { yesBtn = "Yes", noBtn = "No" } = {}) => {
    setData({ title, customContent, yesBtn, noBtn });
    setShowConfirmationModal(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const errorMessage = (ex, title) => {
    setData({ title, error: axiosError.errorDescription(ex), yesBtn: "", noBtn: "Ok" });
    setShowConfirmationModal(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const message = (title, message) => {
    setData({ title, message, yesBtn: "", noBtn: "Ok" });
    setShowConfirmationModal(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const showCustomDialog = (title, customContent) => {
    setData({ title, customContent, yesBtn: "", noBtn: "Close" });
    setShowConfirmationModal(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setShowConfirmationModal(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShowConfirmationModal(false);
  };
  return (
    <AppUiContext.Provider
      value={{
        showConfirmation: handleShow,
        showConfirmYesNo: confirm,
        showCustomConfirm: showCustomConfirm,
        showErrorMessage: errorMessage,
        showMessage: message,
        showCustomDialog: showCustomDialog,
        showLoading: handleShowLoading,
        hideLoading: handleHideLoading,
        showErrorSnackbar: handleShowErrorSnackbar,
        showErrorStringSnackbar: handleShowErrorStringSnackbar,
      }}
    >
      {props.children}
      <Dialog open={showConfirmationModal} onClose={handleCancel}>
        {data.error && (
          <Box m={1}>
            <Alert severity="error" variant="filled" width="100%">
              <AlertTitle>{data.title ?? "Error"}</AlertTitle>
              <Typography>{data.error}</Typography>
            </Alert>
          </Box>
        )}
        {!data.error && (
          <>
            <DialogTitle>{data.title}</DialogTitle>
            <DialogContent>
              {!data.customContent && data.message}
              {data.customContent ?? data.customContent}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" variant="contained">
            {data.noBtn}
          </Button>
          {data.yesBtn && (
            <Button onClick={handleOk} color="primary" variant="contained">
              {data.yesBtn}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </AppUiContext.Provider>
  );
};

const WrappedAppUiProvider = (props) => {
  return (
    <ErrorBoundary>
      <AppUiProvider {...props} />
    </ErrorBoundary>
  );
};

export default WrappedAppUiProvider;

export const useAppUiContext = () => useContext(AppUiContext);
