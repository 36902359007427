import { create } from 'zustand'

import type { UserAuthorizationResultsSchema } from '@/api/types'

import { getErrorMessage } from '@/utils/error'

interface State {
  isLoading: boolean
  initiated: boolean
  profile?: UserAuthorizationResultsSchema | null
  messageDialogOptions?: MessageDialogOptions | null
  messageToastOptions?: MessageToastOptions | null
}

interface Actions {
  setProfile: (profile?: UserAuthorizationResultsSchema | null) => void
  showLoading: VoidFunction
  hideLoading: VoidFunction
  showMessageDialog: (options: MessageDialogOptions) => void
  showErrorDialog: (error: unknown) => void
  hideMessageDialog: VoidFunction
  showMessageToast: (options: MessageToastOptions) => void
  showErrorToast: (error: unknown) => void
  hideMessageToast: VoidFunction
}

interface MessageDialogOptions {
  variant: 'info' | 'success' | 'warning' | 'error'
  title: string
  message: string
}

interface MessageToastOptions {
  variant: 'info' | 'success' | 'warning' | 'error'
  message: string | React.ReactElement
}

let showLoadingTimeout: NodeJS.Timeout | null = null

export const useAppState = create<State & Actions>((set) => ({
  initiated: false,
  isLoading: false,
  messageDialogOptions: null,
  setProfile(profile) {
    set({ profile, initiated: true })
  },
  showLoading() {
    if (showLoadingTimeout) {
      clearTimeout(showLoadingTimeout)
    }
    showLoadingTimeout = setTimeout(() => set({ isLoading: true }), 1000)
  },
  hideLoading() {
    if (showLoadingTimeout) {
      clearTimeout(showLoadingTimeout)
      showLoadingTimeout = null
    }
    set({ isLoading: false })
  },
  showMessageDialog(options) {
    set({ messageDialogOptions: options })
  },
  showErrorDialog(error) {
    set({
      messageDialogOptions: {
        variant: 'error',
        title: 'An error occurred',
        message: getErrorMessage(error),
      },
    })
  },
  hideMessageDialog() {
    set({ messageDialogOptions: null })
  },
  showMessageToast(options) {
    set({ messageToastOptions: options })
  },
  showErrorToast(error) {
    set({
      messageToastOptions: {
        variant: 'error',
        message: getErrorMessage(error),
      },
    })
  },
  hideMessageToast() {
    set({ messageToastOptions: null })
  },
}))

export const app = useAppState.getState()
