import { jwtDecode } from "jwt-decode";
import { localStorageSyncApi } from "./localStorageSyncApi";
import { sessionStorageSyncApi } from "./sessionStorageSyncApi";
import logErrorsService from "./logErrorsService";
import { store } from "../redux/configureStore";
import { showSnackbar } from "../redux/ducks/snackbarDuck";
import { UserAuthorizationResultsSchemaRedirectTo } from "@/api/types";
import { History } from "history";

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

const authService = {
  saveToken(token: string) {
    try {
      logErrorsService.info("saveToken()", token);
      localStorageSyncApi.setJwtToken(token);
      sessionStorageSyncApi.setJwtToken(token);
    } catch (ex) {
      logErrorsService.error(ex, "error in saveToken()");
    }
  },
  saveJwtGuid(jwtGuid: string) {
    sessionStorageSyncApi.setJwtGuid(jwtGuid);
  },
  removeToken() {
    logErrorsService.info("removeToken");
    sessionStorageSyncApi.removeJwtToken();
    localStorageSyncApi.removeJwtToken();
  },
  getToken() {
    let token = sessionStorageSyncApi.getJwtToken();
    if (!token) token = localStorageSyncApi.getJwtToken();
    return token;
  },
  getJwtGuid() {
    return sessionStorageSyncApi.getJwtGuid();
  },
  getDecodedToken() {
    const token = this.getToken();
    if (!token || token === "undefined") {
      return undefined;
    }
    try {
      return jwtDecode<DecodedToken>(token);
    } catch (ex) {
      logErrorsService.error(ex, "Error in jwtDecode");
      return undefined;
    }
  },
  isTokenExpired() {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return true;
    }
    const expirationTime = decodedToken.exp * 1000;
    if (!window.serverTimeOffset) {
      return expirationTime < Date.now();
    }
    const serverTime = Date.now() + (window.serverTimeOffset ?? 0);
    const expired = expirationTime < serverTime;
    if (
      expired &&
      !window.location.href?.endsWith("/login") &&
      !window.location.href?.endsWith("onboarding/new")
    ) {
      store.dispatch(
        showSnackbar({
          variant: "failure",
          message:
            "Your access token has expired. Please login again. If the issue persists, check the date and time on your device to ensure they are correct.",
        })
      );
    }
    return expired;
  },
  getTokenClaim(claimName: string) {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return null;
    }
    return decodedToken[claimName];
  },
  getMillisecondsTillExpiration() {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return 0;
    }
    const currentTimeMs = Date.now();
    const expirationTimeMs = decodedToken.exp * 1000;
    return expirationTimeMs > currentTimeMs ? expirationTimeMs - currentTimeMs : 0;
  },
  isUserAuthenticated() {
    const token = this.getToken();
    if (!token) return false;
    if (this.isTokenExpired()) return false;
    const userId = this.getTokenClaim("UserId");
    return !!userId;
  },
  logToken() {
    const token = this.getToken();
    if (!token) return false;
    if (this.isTokenExpired()) {
      logErrorsService.warning("token expired.", {
        token: localStorageSyncApi.getJwtToken(),
        sessionToken: sessionStorageSyncApi.getJwtToken(),
        clientDateTime: new Date().toString(),
        clientUtcDateTime: new Date().toUTCString(),
        serverTimeOffset: window.serverTimeOffset,
      });
      return false;
    }
    const userId = this.getTokenClaim("UserId");
    logErrorsService.info("token user id:", userId);
    return !!userId;
  },
  isCompanyAuthenticated() {
    const token = this.getToken();
    if (!token) return false;
    return !this.isTokenExpired() && !!this.getTokenClaim("BrandsPay_Conum");
  },
  isIpRestricted() {
    const isUserAuthenticated = this.isUserAuthenticated();
    const redirectTo = this.getTokenClaim("RedirectTo");
    return (
      isUserAuthenticated && redirectTo === UserAuthorizationResultsSchemaRedirectTo.IPRestricted
    );
  },
  isCpaUser() {
    const isUserAuthenticated = this.isUserAuthenticated();
    const cpaFirmId = this.getTokenClaim("CpaFirmId");
    const redirectTo = this.getTokenClaim("RedirectTo");
    return (
      !!isUserAuthenticated &&
      (!!cpaFirmId ||
        redirectTo === UserAuthorizationResultsSchemaRedirectTo.AddCpaUserName ||
        redirectTo === UserAuthorizationResultsSchemaRedirectTo.AddCpaDetails)
    );
  },
  isEssUser() {
    const isUserAuthenticated = this.isUserAuthenticated();
    const essProfileId = this.getTokenClaim("EssProfileId");
    const essOnboardingProfileId = this.getTokenClaim("EssOnboardingProfileId");
    return !!isUserAuthenticated && (!!essProfileId || !!essOnboardingProfileId);
  },
  signOut(currentHistory: History, callback?: VoidFunction) {
    this.removeToken();
    if (callback) {
      callback();
    } else if (currentHistory) {
      currentHistory.push("/auth/login");
    }
  },
  authenticate(currentHistory: History) {
    if (!this.isCompanyAuthenticated()) {
      currentHistory.push("/login");
    }
  },
  getImpersonatedMessage() {
    const impersonatingUser = this.getTokenClaim("IUserId");
    const impersonatingCompany = this.getTokenClaim("IConum");
    if (impersonatingUser) return "Impersonating User";
    if (impersonatingCompany) return "Impersonating Company";
    return;
  },
};

export default authService;
