const TYPES = {
  SET_TOP_PANEL_DATA: "SET_TOP_PANEL_DATA"
};

const initialState = {
  topPanelData: {
    greeting: "",
    lastPayroll: "",
    tiles: []
  }
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_TOP_PANEL_DATA:
      return {
        ...state,
        topPanelData: action.payload.topPanelData
      };
    default:
      return state;
  }
};

export const selectTopPanelData = state => state.dashboard.topPanelData;
