// ACTION TYPES
const TYPES = {
  SET_LIST: "SET_LIST",
  SET_INPUT_FORMAT: "SET_INPUT_FORMAT",
  SET_CHECKS_FILTER: "SET_CHECKS_FILTER",
  SET_DISPLAY_OPTIONS: "SET_DISPLAY_OPTIONS",
  SET_EMPLOYEE_JOBS: "SET_EMPLOYEE_JOBS",
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_FOCUS_VIEW_LIST: "SET_FOCUS_VIEW_LIST",
  SET_PAYROLL_SUMMARY: "SET_PAYROLL_SUMMARY",
  SET_PAYROLL_IMPORT_BATCHES: "SET_PAYROLL_IMPORT_BATCHES",
  SET_SELECTED_CHECKS: "SET_SELECTED_CHECKS",
  CLEAR_SELECTED_CHECKS: "CLEAR_SELECTED_CHECKS",
  SET_SHOW_PAYROLL_DATA_LOADING: "SET_SHOW_PAYROLL_DATA_LOADING",
  SET_DISABLE_CHECKLINE_UPDATES: "SET_DISABLE_CHECKLINE_UPDATES",
  SET_ASSISTANT_MODE: "SET_ASSISTANT_MODE",
};

// ACTION CREATORS

export const setList = (list) => ({
  type: TYPES.SET_LIST,
  payload: { list },
});

export const setInputFormat = (inputFormat) => ({
  type: TYPES.SET_INPUT_FORMAT,
  payload: { inputFormat },
});

export const setChecksFilter = (checksFilter) => ({
  type: TYPES.SET_CHECKS_FILTER,
  payload: { checksFilter },
});

export const setDisplayOptions = (field, value) => ({
  type: TYPES.SET_DISPLAY_OPTIONS,
  payload: { field, value },
});

export const setDebugMode = (debug) => ({
  type: TYPES.SET_DEBUG_MODE,
  payload: { debug },
});

export const setFocusViewList = (list) => ({
  type: TYPES.SET_FOCUS_VIEW_LIST,
  payload: { list },
});

export const setPayrollSummary = (summary) => ({
  type: TYPES.SET_PAYROLL_SUMMARY,
  payload: { summary },
});

export const setPayrollImportBatches = (importBatches) => ({
  type: TYPES.SET_PAYROLL_IMPORT_BATCHES,
  payload: { importBatches },
});

export const clearSelectedChecks = () => ({
  type: TYPES.CLEAR_SELECTED_CHECKS,
});

export const setShowPayrollDataLoading = (loading) => ({
  type: TYPES.SET_SHOW_PAYROLL_DATA_LOADING,
  payload: { loading },
});

export const setDisableChecklineUpdates = (disabled) => ({
  type: TYPES.SET_DISABLE_CHECKLINE_UPDATES,
  payload: { disabled },
});

export const setAssistantMode = (stickyAssistant) => ({
  type: TYPES.SET_ASSISTANT_MODE,
  payload: { stickyAssistant },
});

// REDUCER
const initialState = {
  summary: null,
  list: null,
  inputFormat: "hours",
  checksFilter: { divDeps: [] },
  displayOptions: { manual: true, voided: true },
  jobs: {},
  debug: false,
  focusViewList: [],
  importBatches: [],
  selectedChecks: {},
  showPayrollDataLoading: true,
  disableChecklineUpdates: false,
  stickyAssistant: false,
};

export const payrollsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_LIST:
      return {
        ...state,
        list: action.payload.list,
      };
    case TYPES.SET_INPUT_FORMAT:
      return {
        ...state,
        inputFormat: action.payload.inputFormat,
      };
    case TYPES.SET_CHECKS_FILTER:
      return {
        ...state,
        checksFilter: action.payload.checksFilter,
      };
    case TYPES.SET_DISPLAY_OPTIONS:
      return {
        ...state,
        displayOptions: {
          ...state.displayOptions,
          [action.payload.field]: action.payload.value,
        },
      };
    case TYPES.SET_EMPLOYEE_JOBS:
      return {
        ...state,
        jobs: action.payload.jobs,
      };
    case TYPES.SET_DEBUG_MODE:
      return {
        ...state,
        debug: action.payload.debug,
      };
    case TYPES.SET_FOCUS_VIEW_LIST:
      return {
        ...state,
        focusViewList: action.payload.list,
      };
    case TYPES.SET_PAYROLL_SUMMARY:
      return {
        ...state,
        summary: action.payload.summary,
      };
    case TYPES.SET_PAYROLL_IMPORT_BATCHES:
      return {
        ...state,
        importBatches: action.payload.importBatches,
      };
    case TYPES.SET_SELECTED_CHECKS:
      return {
        ...state,
        selectedChecks: {
          ...state.selectedChecks,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.CLEAR_SELECTED_CHECKS:
      return {
        ...state,
        selectedChecks: {},
      };
    case TYPES.SET_SHOW_PAYROLL_DATA_LOADING:
      return {
        ...state,
        showPayrollDataLoading: action.payload.loading,
      };
    case TYPES.SET_DISABLE_CHECKLINE_UPDATES:
      return {
        ...state,
        disableChecklineUpdates: action.payload.disabled,
      };
    case TYPES.SET_ASSISTANT_MODE:
      return {
        ...state,
        stickyAssistant: action.payload.stickyAssistant,
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectList = (state) => state.payrolls.list;
export const selectInputFormat = (state) => state.payrolls.inputFormat;
export const selectChecksFilter = (state) => state.payrolls.checksFilter;
export const selectDisplayOptions = (state) => state.payrolls.displayOptions;
export const selectDebug = (state) => state.payrolls.debug;
export const selectFocusViewList = (state) => state.payrolls.focusViewList;
export const selectPayrollSummary = (state) => state.payrolls.summary;
export const selectPayrollImportBatches = (state) => state.payrolls.importBatches;
export const selectSelectedChecks = (state) => state.payrolls.selectedChecks;
export const selectShowPayrollDataLoading = (state) => state.payrolls.showPayrollDataLoading;
export const selectDisableChecklineUpdates = (state) => state.payrolls.disableChecklineUpdates;
export const selectStickyAssistant = (state) => state.payrolls.stickyAssistant;
