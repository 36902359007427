import type { SnackbarKey } from 'notistack'

import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'

import { XCloseIcon } from '@/components/ui/icons/x-close'

interface Props {
  snackbarId: SnackbarKey
}

export function SnackbarCloseAction({ snackbarId }: Props) {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
      <XCloseIcon sx={{ color: 'common.white' }} />
    </IconButton>
  )
}
