export const CALENDAR_PERIODS = {
  THIS_YEAR: {
    id: "THIS_YEAR",
    name: "This year"
  },
  LAST_YEAR: {
    id: "LAST_YEAR",
    name: "Last year"
  },
  THIS_QUARTER: {
    id: "THIS_QUARTER",
    name: "This quarter"
  },
  LAST_QUARTER: {
    id: "LAST_QUARTER",
    name: "Last quarter"
  },
  CUSTOM: {
    id: "CUSTOM",
    name: "Custom"
  }
};

export const CALENDAR_PERIODS_DICT = Object.keys(CALENDAR_PERIODS).map(e => ({
  key: e,
  value: CALENDAR_PERIODS[e].name
}));
