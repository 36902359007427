// ACTION TYPES
const TYPES = {
  OPEN_MANAGE_PROFILE: "OPEN_MANAGE_PROFILE",
  CLOSE_MANAGE_PROFILE: "CLOSE_MANAGE_PROFILE"
};

// ACTION CREATORS
export const openManageProfileAC = anchorEl => ({ type: TYPES.OPEN_MANAGE_PROFILE, payload: anchorEl });
export const closeManageProfileAC = () => ({ type: TYPES.CLOSE_MANAGE_PROFILE });

// REDUCER
const initialState = {
  isManageProfileDrawerOpened: false,
  profileMenuAnchorEl: null
};

export const widgetManageProfile = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_MANAGE_PROFILE:
      return {
        ...state,
        isManageProfileDrawerOpened: true,
        profileMenuAnchorEl: action.payload
      };
    case TYPES.CLOSE_MANAGE_PROFILE:
      return {
        ...state,
        isManageProfileDrawerOpened: false,
        profileMenuAnchorEl: null
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsManageProfileOpened = state => state.widgetManageProfile.isManageProfileDrawerOpened
export const selectProfileMenuAnchorEl = state => state.widgetManageProfile.profileMenuAnchorEl
