// ACTION TYPES
const TYPES = {
  SET_SORT_MODEL: "SET_SORT_MODEL",
  SET_FILTER_MODEL: "SET_FILTER_MODEL",
  SET_ORDER_TABLE: "SET_ORDER_TABLE",
  SET_ORDERBY_TABLE: "SET_ORDERBY_TABLE",
  SET_TABLE_ROWS: "SET_TABLE_ROWS",
  SET_SELECT_VALUE: "SET_SELECT_VALUE",
  SET_SELECT_VALUE_V2: "SET_SELECT_VALUE_V2",
  SET_TABLE_PAGE: "SET_TABLE_PAGE",
  SET_EMPLOYEE_IDS: "SET_EMPLOYEE_IDS",
  SET_QUERY: "SET_QUERY",
  SET_EMPLOYEES: "SET_EMPLOYEES",
};

export const setSortModel = (model) => ({
  type: TYPES.SET_SORT_MODEL,
  payload: {
    model,
  },
});

export const setRowsPerPageAC = (rowsPerPage) => ({
  type: TYPES.SET_TABLE_ROWS,
  payload: {
    rowsPerPage,
  },
});

export const setValuesV2AC = (payload) => ({
  type: TYPES.SET_SELECT_VALUE_V2,
  payload,
});

export const setTablePageAC = (page) => ({
  type: TYPES.SET_TABLE_PAGE,
  payload: { page },
});

export const setEmployeeIds = (employeeIds) => ({
  type: TYPES.SET_EMPLOYEE_IDS,
  payload: {
    employeeIds,
  },
});

export const setQuery = (query) => ({
  type: TYPES.SET_QUERY,
  payload: {
    query,
  },
});

export const setEmployees = (employees) => ({
  type: TYPES.SET_EMPLOYEES,
  payload: {
    employees,
  },
});

// REDUCER
const initialState = {
  sortModel: [],
  filterModel: {
    items: [],
    logicOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: [],
  },
  order: "asc",
  orderBy: "employeeName",
  rowsPerPage: 50,
  selectValue: {
    department: "all",
    division: "all",
    status: "Active",
  },
  query: "",
  selectValueV2: {
    divisions: [],
    status: {
      Active: true,
    },
  },
  page: 0,
  employeeIds: [],
  employees: [],
};

export const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload.model,
      };
    case TYPES.SET_FILTER_MODEL:
      return {
        ...state,
        filterModel: action.payload.model,
      };
    case TYPES.SET_ORDER_TABLE:
      return {
        ...state,
        order: action.payload.order,
      };
    case TYPES.SET_ORDERBY_TABLE:
      return {
        ...state,
        orderBy: action.payload.orderBy,
      };
    case TYPES.SET_TABLE_ROWS:
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
      };
    case TYPES.SET_SELECT_VALUE:
      return {
        ...state,
        selectValue: action.payload,
      };
    case TYPES.SET_SELECT_VALUE_V2:
      return {
        ...state,
        selectValueV2: action.payload,
      };
    case TYPES.SET_TABLE_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case TYPES.SET_EMPLOYEE_IDS:
      return {
        ...state,
        employeeIds: action.payload.employeeIds,
      };
    case TYPES.SET_QUERY:
      return {
        ...state,
        query: action.payload.query,
      };
    case TYPES.SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload.employees,
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectSortModel = (state) => state.employees.sortModel;
export const selectFilterModel = (state) => state.employees.filterModel;
export const selectRowsPerPage = (state) => state.employees.rowsPerPage;
export const selectValuesV2 = (state) => state.employees.selectValueV2;
export const selectPage = (state) => state.employees.page;
export const selectEmployeeIds = (state) => state.employees.employeeIds;
export const selectQuery = (state) => state.employees.query;
export const selectEmployees = (state) => state.employees.employees;
