import { useMemo } from "react";
import { AxiosResponse } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { Location } from "history";
import { jwtDecode } from "jwt-decode";
import { apiService, authService, logErrorsService } from "../core";
import { PAGES } from "../routes/pages";
import { localStorageSyncApi } from "../core/localStorageSyncApi";
import { api } from "@/api";
import {
  SwitchProfileRequestSchema,
  SwitchProfileRequestSchemaProfileType,
  UserAuthorizationResultsSchema,
  UserAuthorizationResultsSchemaRedirectTo,
} from "@/api/types";
import { useAppState } from "@/states/app-state";

interface LocationState {
  prevLocation?: string;
  flow?: string;
}

interface CheckRedirectToProps extends UserAuthorizationResultsSchema {
  force?: boolean;
  dashboardOrDefaultPath?: string;
}

export default function useAccountApi() {
  let location = useLocation<LocationState | undefined>();
  let history = useHistory();
  const setProfile = useAppState((state) => state.setProfile);

  function isString(myVar) {
    return typeof myVar === "string" || myVar instanceof String;
  }

  const accuntFunctions = useMemo(
    () => ({
      checkRedirectTo({
        redirectTo,
        dashboardOrDefaultPath,
        force = false,
        selectedCpaFirmId,
      }: CheckRedirectToProps) {
        let prevLocation = location && location.state ? location.state.prevLocation : "";
        if (location?.pathname?.startsWith("/ess/")) prevLocation = location?.pathname ?? "";
        const flow = location && location.state ? location.state.flow : "";
        logErrorsService.info(
          `Entering checkRedirectTo. redirectTo ${redirectTo}, prevLocation ${prevLocation}, flow ${flow}`
        );
        let newPath: Partial<Location<LocationState>> = { pathname: "", state: {} };
        if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.SelectCompany) {
          newPath = { pathname: PAGES.brandsLoginSelectCompany.path, state: { prevLocation } };
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.IPRestricted) {
          newPath = { pathname: PAGES.restrictedIp.path, state: { prevLocation } };
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.Onboarding) {
          if (location.pathname === PAGES.onboarding.path) {
            newPath = {
              pathname: `${PAGES.onboarding.path}/setup`,
              state: { prevLocation },
            };
          } else if (location.pathname.includes("onboarding") && !force) {
            newPath = { pathname: location.pathname, state: { prevLocation } };
          } else {
            newPath = {
              pathname: `${PAGES.onboarding.path}/setup`,
              state: { prevLocation },
            };
          }
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.CpaPortal) {
          newPath = {
            pathname: location.pathname.startsWith("/cpa")
              ? location.pathname
              : PAGES.cpaClients.path,
            state: { prevLocation },
          };
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.AddCpaDetails) {
          newPath = {
            pathname: `${PAGES.cpaSignup.path}/${selectedCpaFirmId}`,
            state: { prevLocation },
          };
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.AddCpaUserName) {
          newPath = { pathname: PAGES.cpaProfile.path, state: { prevLocation } };
        } else if (
          redirectTo === UserAuthorizationResultsSchemaRedirectTo.TwoFactorAuth ||
          redirectTo === UserAuthorizationResultsSchemaRedirectTo.TwoFactorAuthSetup
        ) {
          newPath = { pathname: PAGES.twoFactorAuthLogin.path, state: { prevLocation } };
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.Dashboard) {
          if (location.pathname.startsWith("/cpa")) {
            newPath = { pathname: PAGES.dashboard.path };
          } else if (location.pathname.startsWith("/ess")) {
            newPath = { pathname: PAGES.dashboard.path };
          } else if (location.pathname.startsWith("/employee-onboarding")) {
            newPath = {
              pathname: location.pathname,
              state: { prevLocation },
            };
          } else if (
            prevLocation &&
            prevLocation !== "" &&
            isString(prevLocation) &&
            !prevLocation?.includes("/auth/")
          ) {
            newPath = { pathname: prevLocation };
          } else {
            newPath = { pathname: dashboardOrDefaultPath };
          }
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.EmployeeDeck) {
          if (
            prevLocation &&
            prevLocation !== "" &&
            isString(prevLocation) &&
            !prevLocation?.includes("/ess/setup")
          ) {
            newPath = { pathname: prevLocation };
          } else {
            newPath = { pathname: PAGES.essDashboard.path, state: { prevLocation } };
          }
        } else if (redirectTo === UserAuthorizationResultsSchemaRedirectTo.EmployeeDeckOnboarding) {
          newPath = { pathname: PAGES.essSetup.path, state: { prevLocation } };
        }

        //redirect away from onboarding
        if (
          redirectTo !== UserAuthorizationResultsSchemaRedirectTo.Onboarding &&
          location.pathname?.includes("onboarding") &&
          !location.pathname?.includes("employees-onboarding") &&
          (!newPath || !newPath.pathname) &&
          !location.pathname.startsWith(`${PAGES.onboarding.path}/new`)
        ) {
          newPath = { pathname: PAGES.dashboard.path };
        }
        if (newPath && newPath.pathname && newPath.pathname !== location.pathname) {
          const split = newPath.pathname.split("?");
          const search = split?.length > 1 ? split[1] : "";
          logErrorsService.info(`Redirecting to ${newPath.pathname}.`);
          history.push({
            pathname: split[0],
            search: search,
            state: {
              prevLocation: location?.state?.prevLocation,
              ...newPath.state,
            },
          });
        }
      },
      async login(response: AxiosResponse<UserAuthorizationResultsSchema>) {
        this.basicVerifyPasswordLogic(response);
        if (response && response.data) {
          localStorageSyncApi.checkVersion(response.data.version);
          this.checkRedirectTo({
            ...response.data,
            dashboardOrDefaultPath: PAGES.dashboard.path,
          });
        }

        // Do not remove onboarding guid unless login is successful
        const successfulLoginRedirectPaths = [
          UserAuthorizationResultsSchemaRedirectTo.Dashboard,
          UserAuthorizationResultsSchemaRedirectTo.SelectCompany,
          UserAuthorizationResultsSchemaRedirectTo.Onboarding,
        ];
        if (successfulLoginRedirectPaths.some((r) => r === response.data?.redirectTo)) {
          localStorageSyncApi.removeGuid();
        }
      },
      async switchProfile(
        profileType: SwitchProfileRequestSchemaProfileType,
        profileId: number,
        onboardingCode?: string
      ) {
        let payload: SwitchProfileRequestSchema = {
          profileType,
          id: profileId,
          employeeOnboardingCode: onboardingCode,
        };
        const response = await api.switchProfile(payload);
        // We first switch the token and profile data before
        // Before we were changing the data after redirecting the user
        // These caused issues of api returning data using the old token
        this.basicVerifyPasswordLogic(response);
        // after we switch the token and updated the profile
        // we check if we need to redirect the user somewhere else
        this.checkRedirectTo(
          {
            ...response.data,
            force: true,
          }
          // response.data.redirectTo,
          // null,
          // response.data.currentOnboardingStage,
          // true
        );
        return response.data;
      },
      async getPpxUrl() {
        const { data } = await apiService.get("api/accounts/PPX_URL");
        return data;
      },
      async getPpxSignoutUrl() {
        const { data } = await apiService.get("api/accounts/PPX_LOGOUT_URL");
        return data;
      },
      async getProfile() {
        try {
          const response = await api.getProfile();
          this.basicVerifyPasswordLogic(response);
          localStorageSyncApi.checkVersion(response.data?.version);
          this.checkRedirectTo(response.data);
          return response.data;
        } catch (err) {
          authService.removeToken();
          console.log("Error getting profile");
          setProfile(null);
          history.push({
            pathname: PAGES.brandsLogin.path,
            state: { prevLocation: history.location.pathname, error: "Not logged in" },
          });
        }
        return null;
      },
      basicVerifyPasswordLogic(response: AxiosResponse<UserAuthorizationResultsSchema>) {
        if (response.status === 401) {
          try {
            authService.removeToken();
          } catch (error) {
            console.error(error, "error removing token");
          }
          window.location.replace(PAGES.brandsLogin.path);
        } else {
          const { token, jwtGuid } = response.data;
          authService.saveToken(token);
          authService.saveJwtGuid(jwtGuid);
          let decoded = jwtDecode(token);
          window.serverTimeOffset = decoded.iat - Math.floor(Date.now() / 1000);
          setProfile(response.data);
        }
      },
      async getSwipeClockUrl() {
        const { data } = await apiService.get("api/SwipeClock/SSO");
        return data.url;
      },
      async getHrNextDirectSsoUrl() {
        const { data } = await apiService.get("api/HrNextDirect");
        return data.url;
      },
      async getSwipeClockSignoutUrl() {
        return "";
      },
      async getSwipeLogins() {
        const { data } = await apiService.get("api/SwipeClock/logins");
        return data;
      },
    }),
    [history, location]
  );
  return accuntFunctions;
}
