/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Brands Web API v2
 * OpenAPI spec version: v2
 */

export type UserAuthorizationResultsSchemaRedirectTo = typeof UserAuthorizationResultsSchemaRedirectTo[keyof typeof UserAuthorizationResultsSchemaRedirectTo];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAuthorizationResultsSchemaRedirectTo = {
  Dashboard: 0,
  TwoFactorAuth: 1,
  TwoFactorAuthSetup: 2,
  SelectCompany: 3,
  Login: 4,
  ResetPassword: 5,
  EmailNotConfirmed: 6,
  IPRestricted: 7,
  Onboarding: 8,
  CpaPortal: 9,
  AddCpaDetails: 10,
  AddCpaUserName: 11,
  EmployeeDeck: 12,
  EmployeeDeckOnboarding: 13,
} as const;
