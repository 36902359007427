import React from "react";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";

import config from "devextreme/core/config";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./custom-devextreme.css";

window.global ||= window;

Sentry.init({
  dsn: "https://fa467857421d5db4a59d1aac07a03dd6@o289996.ingest.us.sentry.io/4508053398880256",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/brandspaycheck\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.REACT_APP_ENV === "production",
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

LicenseInfo.setLicenseKey(
  "31138f439701c5687f4c1e9366b82a1eTz04MjcwNixFPTE3Mzc1NjY0ODIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

config(
  "ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImRhYmNhMDc2LTVmZGYtMTFlNC04MGMwLTAwMjU5MGQ5ZDVmZiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.b8KkLgI0uX71NJIpAFwXX9vgZEuU0Iurg48lqkv/LZKY0SWjbyJD1U/bZsFVejpkntHxXg74wDXzjtodyauJnH5QSSnjuDgr1kR3RWC4TSjF3ovaHvqdWKZVLYLnRbapZwRbCw=="
);

root.render(<App />);
