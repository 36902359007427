// ACTION TYPES
const TYPES = {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR"
};

// ACTION CREATORS
export const showSnackbar = (payload) =>{
  return ({ 
    type: TYPES.SHOW_SNACKBAR,
    payload: payload
  })};

export const hideSnackbar = () => {
  return ({ type: TYPES.HIDE_SNACKBAR })
};

// REDUCER
const initialState = {
  show: false,
  variant: '',
  message: '',
};

export const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SHOW_SNACKBAR:
      return {
        ...state,
        show: true,
        variant: action.payload.variant,
        message: action.payload.message
      };
    case TYPES.HIDE_SNACKBAR:
      return {
        ...state,
        show: false,
        variant: '',
        message: ''
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectSnackbar = state => state.snackbar;
