import { createTheme } from "@mui/material/styles";

import { theme as defaultTheme } from "../../muiTheme";
import ArrowForwardIosIcon from "../../components/Icons/ArrowForwardIosIcon";
import CheckboxIcon from "../../components/Icons/Payrolls/CheckboxIcon";
import CheckboxCheckedIcon from "../../components/Icons/Payrolls/CheckboxCheckedIcon";

export const theme = createTheme({
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    h3: {
      fontSize: 18,
      lineHeight: "normal",
      fontWeight: 500,
    },
    h6: {
      fontSize: 13,
      lineHeight: "normal",
      fontWeight: 400,
    },
  },
  palette: {
    ...defaultTheme.palette,
    purple: {
      light: "#FAF9FF",
      main: "#320DA0",
      dark: "#30254F",
    },
    error: {
      main: "#F04438",
    },
    yellow: {
      light: "#FFE792",
      main: "#DB8C15",
    },
    gray: {
      main: "#76747C",
    },
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      ...defaultTheme.components.MuiButton,
      styleOverrides: {
        ...defaultTheme.components.MuiButton.styleOverrides,
        outlinedInfo: ({ theme }) => {
          return {
            border: "1px solid rgba(109, 105, 121, 0.25)",
            color: "#6D6979",
            backgroundColor: theme.palette.common.white,
            "&:hover": {
              border: "1px solid rgba(109, 105, 121, 0.5)",
              color: "#6D6979",
              backgroundColor: "rgba(109, 105, 121, 0.05)",
            },
          };
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        variant: "elevation",
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          // borderBottom: `1px solid ${theme.palette.purpleLight.main}`,
          borderRadius: theme.spacing(0.625),
          // marginBottom: theme.spacing(1.875),
          // "&:before": {
          //   display: "none",
          // },
          "&.Mui-disabled": {
            border: `1px solid ${theme.palette.purpleLight.main}`,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: (
          <ArrowForwardIosIcon
            sx={{
              fontSize: 12,
              color: "purple.dark",
            }}
          />
        ),
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          paddingLeft: theme.spacing(1),
          borderRadius: theme.spacing(0.625),
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper": {
            transform: "rotate(90deg)",
            padding: theme.spacing(1.5),
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(270deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(0),
          },
        }),
        content: ({ theme }) => ({
          color: theme.palette.purple.dark,
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2, 2, 2, 5.5),
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon sx={{ fill: "white" }} />,
        checkedIcon: <CheckboxCheckedIcon sx={{ fill: "white" }} />,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: "18px",
          borderColor: "#E9E6F9",
          "& .MuiOutlinedInput-notchedOutline": {},
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
              borderColor: "#6D6979",
            },
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#6D6979",
            },
          },
        }),
        input: ({ theme, ownerState }) => ({
          height: "unset",
          padding: theme.spacing(1.5, 1.5),
          ...(ownerState.size === "large" && {
            padding: theme.spacing(2, 1.5),
          }),
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: "#E9E6F9",
          legend: {
            maxWidth: 0,
          },
        }),
      },
    },
  },
});
