import axios from 'axios'

import { app } from '@/states/app-state'
import { apiService, urlHelpers } from '~/core'

export * as api from './generated'

axios.interceptors.request.use(
  (request) => {
    app.showLoading()

    request.url = urlHelpers.getAbsoluteURL(request.url) as string

    for (const [key, value] of Object.entries(apiService.getAxiosConfig())) {
      request.headers.set(key, value as string)
    }

    return request
  },
  (error) => {
    app.hideLoading()
    return Promise.reject(error as Error)
  },
)

axios.interceptors.response.use(
  (response) => {
    app.hideLoading()
    return response
  },
  (error) => {
    app.hideLoading()
    return Promise.reject(error as Error)
  },
)
